import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddMarketTokenModal(props) {
    console.log(props);
    const [date, setDate] = useState("")
    const [state, setState] = useState({ name: "", dbiUsdt: "", country_id: "",dbiEth:"", date_of_live: "", quantity: "", user_limit: "", user_min_limit: "", description: "", date_of_expire: "", link: "" });
    const [errorState, setErrorState] = useState({})
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [imageData, setImageData] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [dateErr, setDateErr] = useState("")
    const [titleErr, setTitleErr] = useState("")
    const [descriptionErr, setDescriptionErr] = useState("")
    const [imageErr, setImageErr] = useState("")
    const [language, setLanguage] = useState("en")
    const [linkErr, setLinkErr] = useState("")
    const [blogLink, setBlogLink] = useState("")
    const [buttonDisable, setButtonDisable] = useState(false)
    const [rateState, setRateState] = useState({ totalPrice: "", dbiRate: "",dbiEth:"", dbiTotalRate: "",totalEthPrice:"",ethRate:"" })
    const [dbiUsdtCoin, setDbiUsdtCoin] = useState({});


    useEffect(() => {
        setErrorState({})
        setState({ name: "", dbiUsdt: "", country_id: "", date_of_live: "",dbiEth:"", quantity: "", user_limit: "", user_min_limit: "", description: "", date_of_expire: "", link: "" });
        setRateState({ totalPrice: "", dbiRate: "",dbiEth:"", dbiTotalRate: "", totalEthPrice:"",ethRate:"" })
        //getCoinPair()

    }, [])


    const handleChange = (e) => {
        let error = {};
        const { name, value } = e.target;
        let totalPrice = "";
        console.log(name);
        console.log(value, rateState);
        // error[name] = "";
        if (value == "") {
            error[name] = "This field is required"
        } else {
            if (name == "quantity") {
                if (value <= 0) {
                    error[name] = "Please enter valid number"
                }
            }
            else if (name == "user_min_limit") {
                if (value <= 0) {
                    error[name] = "Please enter valid number"
                } else if (state.user_limit && parseFloat(value) > state.user_limit) {
                    console.log(parseFloat(value), "  ", state.user_limit);
                    error[name] = "Please enter value less then user max"
                }
            }
            else if (name == "user_limit") {
                if (value <= 0) {
                    error[name] = "Please enter valid number"
                } else if (parseFloat(value) < state.user_min_limit) {
                    console.log(value, "  ", state.user_min_limit);
                    error[name] = "Please enter value more then user min"
                } else if (parseFloat(value) > state.quantity) {
                    error[name] = "Enter value not greater then available quantity."
                }
            }
            else if (name == "name") {
                if (value.length <= 2) {
                    error[name] = "Please enter more then 2 charater."
                }
            }
            else if (name == "dbiUsdt") {
                if (value == 0) {
                    error[name] = "The Field is required";
                }
                else if (value < 0) {
                    error[name] = "The Price should not be negative";
                } else {
                    totalPrice = value * rateState.dbiRate;
                    totalPrice = totalPrice.toFixed(8);
                    // setState({ ...state, totalPrice: totalPrice })
                    setRateState({ ...rateState, totalPrice: totalPrice })

                }
            }
            else if (name == "dbiEth") {
                if (value == 0) {
                    error[name] = "The Field is required";
                }
                else if (value < 0) {
                    error[name] = "The Price should not be negative";
                } else {
                    totalPrice =  (rateState.ethRate && rateState.ethRate != '') ? value * rateState.ethRate : 0;
                   // console.log(totalPrice);
                    totalPrice = totalPrice.toFixed(8);
                    // setState({ ...state, totalPrice: totalPrice })
                    setRateState({ ...rateState, totalEthPrice: totalPrice })

                }
            }
            else if (name == "date_of_expire") {
                const dateE = new Date(value);
                const dateL = new Date(state.date_of_live);
                console.log(dateE)
                console.log(dateL)
                console.log(dateL > dateE)
                if (dateL > dateE) {
                    error[name] = "The Date Must be greater then Live date";
                }

            }else if(name == "link") {
                if (value == "") {
                error[name] = "This field is required";
                }else if(!validURL(value)) {
                    error[name] = "Invalid url";
                }
            }
        }
        setState({ ...state, [name]: value })

        console.log(error);
        setErrorState(error)
    }

    const getCoinPair = () => {

        axios.get(baseUrl + "/api/pair-data", { header: description, lang: language }).then((res) => {
            console.log(res.data);
            if (res.data) {
                const pairs = res.data;
                let pairPrice = pairs.find((val) => val.id == 235);
                // setDbiUsdtCoin(pairPrice)
                // setRateState({...rateState,dbiRate:pairPrice.current_price})
                // console.log(pairPrice);
            }
        }).catch((err) => {

        })
    }

    const handleChangeRate = (e) => {
        let error = {};
        const { name, value } = e.target;
        console.log(state);
        console.log(name);
        console.log(value);

        

        if (name == "dbiRate") {
            let tPrice = value * state.dbiUsdt;
            setRateState({ ...rateState, [name]: value, totalPrice: tPrice })
            if (value == 0) {
                error[name] = "The Field is required";
            } else {
                if (value <= 0) {
                    error[name] = "Please enter valid DBI";
                }
            }

        }

        if (name == "ethRate") {
            let tPrice = value * state.dbiEth;
            setRateState({ ...rateState, [name]: value, totalEthPrice: tPrice })
            if (value == 0) {
                error[name] = "The Field is required";
            } else {
                if (value <= 0) {
                    error[name] = "Please enter valid DBI";
                }
            }

        }

        setErrorState(error)

    }

    function validURL(str) {
        var pattern = new RegExp('^((ft|htt)ps?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name and extension
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?'+ // port
  '(\\/[-a-z\\d%@_.~+&:]*)*'+ // path
  '(\\?[;&a-z\\d%@_.,~+&:=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        console.log(name);
        if (name === "date") {
            setDate(value)
            if (value === "") {
                setDateErr("This field is required")
                return false
            }
            setDateErr("")
        }
        if (name === "title") {
            setTitle(value)
            if (value == "") {
                setTitleErr("This field is required")
                return false
            }
            setTitleErr("")
        }
        if (name === "lang") {
            setLanguage(value)
        }
        if (name === "link") {
            let reg = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");


            setBlogLink(value)
            if (value == "") {
                setLinkErr("This field is required")
                return false
            } else if (!reg.test(value)) {
                setLinkErr("Please Enter valid Url")
                return false
            }
            setLinkErr("")

        }
        // if(name==="description"){
        //     setDescription(value)
        //     if(value===""){
        //         setDescriptionErr("This field is required")
        //         return false
        //     }
        //     setDescriptionErr("")
        // }
    }
    const closeModal = () => {
        console.log("close");
        setErrorState({})
        setState({ name: "", dbiUsdt: "", country_id: "", date_of_live: "", quantity: "", user_limit: "", user_min_limit: "", description: "", date_of_expire: "", link: "" })
        setImage("")
        setImageUrl("")
        setRateState({ totalPrice: "", dbiRate: "", dbiTotalRate: "" })
        // setImageData("")
        // setImage("")
        // setImageUrl("")
        // setTitle("")
        // setDescription("")
        // setDateErr("")
        // setTitleErr("")
        // setDescriptionErr("")
        // setLinkErr("")
        // setBlogLink("")
        // setImageErr("")
        // setLanguage("en")
    }

    const handleImage = (e) => {
        let { value } = e.target;

        const icon = e.target.files[0];

        if (value !== "" || value !== undefined) {
            setImage(e.target.files[0]);
            setImageUrl(URL.createObjectURL(e.target.files[0]));
            setImageErr("");
            setErrorState({ ...errorState, image: "" })
        } else {
            setImage(e.target.files[0]);
            setImageUrl("");
            setImageErr("This field is required");
        }
        if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
            setImageErr("Select valid image format");
            setErrorState({...errorState,image:"Select valid image format"})
            
            return false;
        }
    };

    const handleSubmit = () => {
        console.log(state);

        // setDescriptionErr("")
        let error = {};
        if (state.name == undefined || state.name === "") {
            error['name'] = "This field is required";
            // setDateErr("This field is required")
            // return false
        } else {
            if (state.name.trim() == "") {
                error['name'] = "The input value is  Invalid";
            }
        }

        if (state.dbiUsdt == undefined || state.dbiUsdt === "") {
            error['dbiUsdt'] = "This field is required";
            //setTitleErr("This field is required")
            // return false
        } else {
            if (state.dbiUsdt <= 0) {
                error['dbiUsdt'] = "Please enter valid Price";
            }
        }

        if (rateState.dbiRate == undefined || rateState.dbiRate === "") {
            error['dbiRate'] = "This field is required";
            //setTitleErr("This field is required")
            // return false
        } else {
            if (rateState.dbiRate <= 0) {
                error['dbiRate'] = "Please enter valid DBI";
            }
            
        }

        if (state.link == undefined || state.link === "") {
            error['link'] = "This field is required";
            //setTitleErr("This field is required")
            // return false
        } else {
            if (!validURL(state.link)) {
                error['link'] = "Please enter valid link";
            }
        }


        //if (state.country_id == undefined || state.country_id === "") {
        // error['country_id'] = "This field is required";
        // setLinkErr("This field is required")
        // return false
        //}
        if (state.date_of_live == undefined || state.date_of_live === "") {
            error['date_of_live'] = "This field is required";
            //setDescriptionErr("This field is required")
            //  return false
        }
        if (state.date_of_expire == undefined || state.date_of_expire === "") {
            error['date_of_expire'] = "This field is required";
            //setDescriptionErr("This field is required")
            //  return false
        } else {
            const dateE = new Date(state.date_of_expire);
            const dateL = new Date(state.date_of_live);
            console.log(dateE)
            console.log(dateL)
            console.log(dateL > dateE)
            if (dateL > dateE) {
                error['date_of_expire'] = "The Date Must be greater then Live date";
            }

        }
        if (state.quantity == undefined || state.quantity === "") {
            error['quantity'] = "This field is required";
            // setImageErr("This field is required")
            //return false
        }
        else {
            if (state.quantity <= 0) {
                error['quantity'] = "Please enter valid Quantity";
            }
        }
        if (state.user_limit == undefined || state.user_limit === "") {
            error['user_limit'] = "This field is required";
            //setImageErr("This field is required")
            //return false
        }
        else {
            if (state.user_limit <= 0) {
                error['user_limit'] = "Please enter valid User limit";
            } else if (parseFloat(state.user_limit) < parseFloat(state.user_min_limit)) {
                error['user_limit'] = "Please enter value more then user min"
            } else if (parseFloat(state.user_limit) > state.quantity) {
                error['user_limit'] = "Enter value not greater then available quantity."
            }
        }
        if (state.user_min_limit == undefined || state.user_min_limit === "") {
            error['user_min_limit'] = "This field is required";
            //setImageErr("This field is required")
            //return false
        }
        else {
            if (state.user_min_limit <= 0) {
                error['user_min_limit'] = "Please enter valid User limit";
            } else if (parseFloat(state.user_limit) < parseFloat(state.user_min_limit)) {
                error['user_min_limit'] = "Please enter value less then user max"
            } else if (parseFloat(state.user_min_limit) > parseFloat(state.quantity)) {
                error['user_min_limit'] = "Enter value not greater then available quantity."
            }
        }
        if (state.description == undefined || state.description === "") {
            error['description'] = "This field is required";
            //setImageErr("This field is required")
            //return false
        }
        console.log(image);
        if (image === "") {
            error['image'] = "This field is required";
            //setImageErr("This field is required")
            //return false
        }else if(image.type != "image/png" && image.type != "image/jpeg") {
            error['image'] = "Please select image only";
        }
        console.log(error);
        setErrorState(error)
        if (Object.keys(error).length == 0) {
            setButtonDisable(true)
            let formdata = new FormData
            formdata.append("name", state.name)
            //  formdata.append("price", state.price)
            formdata.append("price", rateState.totalPrice)

            // formdata.append("country_id", state.country_id)
            formdata.append("date_of_expire", state.date_of_expire)
            formdata.append("description", state.description)
            formdata.append("user_min_limit", state.user_min_limit)
            formdata.append("symbol", image);
            formdata.append("dbiRate", rateState.dbiRate);
            formdata.append("dbiUsdt", state.dbiUsdt)
            formdata.append("dbiEth", state.dbiEth)
            formdata.append("ethRate", rateState.ethRate)

            formdata.append("date_of_live", state.date_of_live)
            formdata.append("quantity", state.quantity)
            formdata.append("user_limit", state.user_limit);
            formdata.append("link", state.link);
            console.log(formdata);
            axios.post(baseUrl + "/api/add_market_token", formdata).then((res) => {
                if (res.data.status) {
                    setState({ name: "", dbiUsdt: "", country_id: "", date_of_live: "", quantity: "", user_limit: "", user_min_limit: "", description: "", date_of_expire: "", link: "" })
                    props.setRefresh(!props.refresh)
                    $('#add-user-modal').modal('hide');
                    closeModal()
                } else {
                    setButtonDisable(false)
                }
            }).catch((err) => {
                console.log(err)
                setButtonDisable(false)
            })
        }
    }

    return (
        <div>
            <div className="modal fade" id="add-user-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Market Token</h4>
                            <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="update-user">
                                <div className='mb-3'>
                                    <label className='form-label'>Token Name:</label>
                                    <input type='text' name="name" value={state.name} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{errorState.name}</span>

                                </div>
                                <div className='mb-3 row'>
                                    <div className='col-lg-6'>
                                        <label className='form-label'>Price(USDT):</label>
                                        <input type='number' name="dbiUsdt" value={state.dbiUsdt} onChange={handleChange} className='form-control' />
                                        <span style={{ color: "red" }}>{errorState.dbiUsdt}</span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <label className='form-label'>DBI:</label>
                                        <input type='number' name="dbiRate" value={rateState.dbiRate} className='form-control' onChange={handleChangeRate} />
                                        <span style={{ color: "red" }}>{errorState.dbiRate}</span>

                                    </div>
                                    <div className='col-lg-3'>
                                        <label className='form-label'>Total:</label>
                                        <input type='text' name="totalPrice" value={rateState.totalPrice} disabled className='form-control' />

                                    </div>
                                </div>
                                <div className='mb-3 row'>
                                    <div className='col-lg-6'>
                                        <label className='form-label'>Price(ETH):</label>
                                        <input type='number' name="dbiEth" value={state.dbiEth} onChange={handleChange} className='form-control' />
                                        <span style={{ color: "red" }}>{errorState.dbiEth}</span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <label className='form-label'>DBI:</label>
                                        <input type='number' name="ethRate" value={rateState.ethRate} className='form-control' onChange={handleChangeRate} />
                                        <span style={{ color: "red" }}>{errorState.ethRate}</span>

                                    </div>
                                    <div className='col-lg-3'>
                                        <label className='form-label'>Total:</label>
                                        <input type='text' name="totalPrice" value={rateState.totalEthPrice} disabled className='form-control' />

                                    </div>
                                </div>
                                {/* <div className='mb-3'>
                                    <label className='form-label'>Country:</label>
                                    <select onChange={handleChange} value={state.country_id} name="country_id" className="form-select w-100 form-control">
                                        <option value="">Select</option>
                                        {props.countryList.map((val, index) =>
                                            <option value={val.id} key={index}>{val.name}</option>
                                        )}                                      
                                    </select>
                                    <span style={{ color: "red" }}>{errorState.country_id}</span>
                                </div> */}
                                <div className='mb-3'>
                                    <label className='form-label'>Whitepaper Link:</label>
                                    <input type="text" name="link" value={state.link} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.link}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Date of live:</label>
                                    <input type="date" name="date_of_live" value={state.date_of_live} onChange={handleChange} className='form-control' min={new Date().toJSON().slice(0, 10)} />

                                    <span style={{ color: "red" }}>{errorState.date_of_live}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Date of Expire:</label>
                                    <input type="date" name="date_of_expire" value={state.date_of_expire} onChange={handleChange} className='form-control' min={new Date().toJSON().slice(0, 10)} />

                                    <span style={{ color: "red" }}>{errorState.date_of_expire}</span>
                                </div>

                                <div className='mb-3'>
                                    <label className='form-label'>Available quantity:</label>
                                    <input type='number' name="quantity" value={state.quantity} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.quantity}</span>
                                </div>

                                <div className='mb-3'>
                                    <label className='form-label'>Per User Min Limit:</label>
                                    <input type='number' name="user_min_limit" value={state.user_min_limit} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.user_min_limit}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Per User Max Limit:</label>
                                    <input type='number' name="user_limit" value={state.user_limit} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.user_limit}</span>
                                </div>

                                <div className='mb-3'>
                                    <label className='form-label'>Description:</label>
                                    <textarea type='number' name="description" value={state.description} onChange={handleChange} className='form-control' >
                                    </textarea>

                                    <span style={{ color: "red" }}>{errorState.description}</span>
                                </div>
                                <div className='mb-3'>
                                    <img
                                        style={{ width: "100px" }}
                                        src={imageUrl}
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <label className='form-label'>Image:</label>
                                    <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" value={imageData} />
                                    <span style={{ color: "red" }}>{errorState.image}</span>
                                </div>




                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                            <button
                                disabled={buttonDisable}
                                form="update-user"
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddMarketTokenModal
