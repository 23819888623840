import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Moment from "moment";
import $ from "jquery";
import * as myConstList from "./baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import UserFund from "../partials/UserFund";
import UserFundModal from "../partials/UserBuyFundModel";
import UserSellFundModal from "../partials/UserSellFundModal";
import UserReferModal from "../partials/UserReferModal";
import UserWithdrawalModal from "../partials/UserWithdrawalModal";
import UserDepositeModal from "../partials/UserDepositeModal";
import UserTransferModal from "../partials/UserTransferModel";
const baseUrl = myConstList.baseUrl;
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };

    this.columns = [
      {
        key: "sno",
        text: "#",
        className: "sno",
        align: "left",
        sortable: true,
        cell: (row, index) => row.sno,
      },
      {
        key: "id",
        text: "id",
        className: "ids",
        align: "left",
        sortable: true,
        cell: (row, index) => {
          //"/user-detail/"+row.id
          return <a href={""}>
          {row.id}
          </a>
          },
      },
      // {
      //   key: "AccHolder",
      //   text: "Name",
      //   className: "firstName",
      //   align: "left",
      //   sortable: true,
      // },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "",
        text: "Name",
        className: "email",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <>
              {(record.firstName) ? record.firstName:"" }
            </>
          );
        },
      },
      {
        key: "mobile_no",
        text: "Mobile No.",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "country_name",
        text: "Country",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key:"",
        text: "KYC Status",
        className: "Status",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {record.kycVerification_status == "Y" ? "Verified" : "Pending"}
            </Fragment>
          );
        },
      },
      // {
      //   text: "User 2FA Status",
      //   className: "Status",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         {record.enable_2fa == "Y" ? (
      //           <button onClick={() => this.Enable2faMgs(record)}>
      //             Enabled
      //           </button>
      //         ) : (
      //           <button onClick={() => this.disable2faMgs(record)}>
      //             Disabled
      //           </button>
      //         )}
      //       </Fragment>
      //     );
      //   },
      // },
      {
        key: "createdAt",
        text: "Date and Time",
        className: "createdAt",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{Moment(record.createdAt).format("lll")}</Fragment>;
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {/* <a
                href={"/profile-update/" + record.id}
                className="btn btn-primary btn-sm"
                title="Edit Details"
              >
                <i className="fa fa-edit"></i>
              </a> */}
               {/* <button
                  className="btn btn-primary mr-2"
                  title="Enable 2fa"
                  onClick={() => this.generateWallet(record)}
                >
                  WALL
                </button> */}
              {/* <a
                href={"/verifyKyc/" + record.id + "/" + 1}
                className="admin_btn mr-2 ml-2 pt-1 pb-1"
                title="KYC"
              >
                KYC
              </a> */}
              <button
                data-toggle="modal"
                data-target="#update-fund-modal"
                className="btn btn-primary btn-sm mr-2"
                onClick={() => this.editRecord(record)}
                title="User Fund"
              >
                <i class="fa fa-university" aria-hidden="true"></i>
              </button>
              <button
                className={
                  record.is_deleted == 1
                    ? "btn btn-danger btn-sm"
                    : "btn btn-success btn-sm"
                }
                title={record.is_deleted == 1 ? "Unblock User" : "Block User"}
                onClick={() => this.deleteRecordMgs(record, record.is_deleted)}
              >
                <i class="fa fa-lock"></i>
              </button>
              {record.enable_2fa == "Y" ? (
                <button
                  className="btn btn-success btn-sm"
                  title="Disable 2fa"
                  onClick={() => this.Enable2faMgs(record)}
                >
                  <img
                    src="/img/2fa-icon1.png"
                    alt=""
                    className="auth-img img-fluid"
                  />
                </button>
              ) : (
                <button
                  className="btn btn-danger btn-sm"
                  title="Enable 2fa"
                  onClick={() => this.disable2faMgs(record)}
                >
                  <img
                    src="/img/2fa-icon2.png"
                    alt=""
                    className="auth-img img-fluid"
                  />
                </button>
              )}
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Users List",
      no_data_text: "No user found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
    };

    this.state = {
      currentRecord: {
        id: "",
        name: "",
        email: "",
        password: "",
        password2: "",
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidUpdate() {
    if (this.state.count <= 3) {
      this.getData();
      this.setState({ count: this.state.count + 1 }, () => {
        console.log("componentdidupdate", this.state.count);
      });
    }
  }

  componentDidMount() {
    if (
      this.props.auth.user.manageUsers !== undefined &&
      this.props.auth.user.manageUsers !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    if (
      this.props.userdata !== undefined &&
      this.props.userdata.newuser !== undefined &&
      this.props.userdata.newuser.data !== undefined &&
      this.props.userdata.newuser.data.message !== undefined
    ) {
      toast(this.props.userdata.newuser.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.auth.user.manageUsers !== undefined &&
      this.props.auth.user.manageUsers !== 1
    ) {
      this.props.history.push("/dashboard");
    }

    this.getData();
  }
  Enable2faMgs(record) {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to disable 2FA of this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.userKycStatus(record),
        },
        {
          label: "No",
        },
      ],
    });
    this.getData();
  }

  disable2faMgs(record) {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to enable 2FA of this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.UserEnable2faStatus(record),
        },
        {
          label: "No",
        },
      ],
    });
    this.getData();
  }

  getData() {
    axios
      .get(baseUrl + "/api/get-all-user")
      .then((res) => {
        this.setState({ records: res.data });
      })
      .catch();
  }

  editRecord(record) {
    console.log("user_id:=> ",record.id);
    this.setState({
      user: record.id,
    });
    let data = {
      userId: record.id,
    };
    axios
      .post(baseUrl + "/frontapi/wallet_Coin_Total_Balance_admin", data)
      .then((res) => {
        this.setState({ currentRecord: res.data });
      })
      .catch();
    axios
      .post(baseUrl + "/frontapi/wallet_Total_Balance_admin", data)
      .then((res) => {
        var resd = res.data;
        this.setState({ portfolioRecord: resd.data });
      })
      .catch();
    axios
      .post(baseUrl + "/api/sell-order-data-by-id", data)
      .then((res) => {
        this.setState({ sellOrderDataById: res.data });
      })
      .catch((e)=>{
          console.log("Error: ",e)
      });
    axios
      .post(baseUrl + "/api/buy-order-data-by-id", data)
      .then((res) => {
        this.setState({ buyOrderDataById: res.data });
      })
      .catch((e)=>{
        console.log("Error: ",e)
    });
    axios
      .post(baseUrl + "/frontapi/user-refer-history-by-id", data)
      .then((res) => {
        this.setState({ referHistory: res.data });
      })
      .catch();
    axios
      .post(baseUrl + "/frontapi/user-withdraw-history-by-id", data)
      .then((res) => {
        console.log("withrawHistory", res.data);
        this.setState({ withrawHistory: res.data });
      })
      .catch();
    axios
      .post(baseUrl + "/frontapi/user-deposite-history-by-id", data)
      .then((res) => {
        console.log("withrawHistory", res.data);
        this.setState({ depositeHistory: res.data });
      })
      .catch();
    axios
      .post(baseUrl + "/frontapi/user-transfer-history-by-id", data)
      .then((res) => {
        console.log("withrawHistory", res.data);
        this.setState({ transferHistory: res.data });
      })
      .catch();
  }

  generateWallet(record) {
    console.log(record.id);    
    axios
    .get(baseUrl + "/api/generate_user_wallet_address/"+record.id)
    .then((res) => {
      console.log(res);
      toast("Success", {
        position: toast.POSITION.TOP_CENTER,
      });
    })
    .catch((e)=>{
      console.log("Error: ",e)
  });
  }

  deleteRecordMgs(record, manageUser) {
    console.log("manageuser", manageUser);

    manageUser == 0
      ? confirmAlert({
          title: "Confirm to submit",
          message: "Really want to block this user ?",
          buttons: [
            {
              label: "Yes",
              onClick: () => this.deleteRecord(record),
            },
            {
              label: "No",
            },
          ],
        })
      : confirmAlert({
          title: "Confirm to submit",
          message: "Really want to unblock this user ?",
          buttons: [
            {
              label: "Yes",
              onClick: () => this.unBlockedRecord(record),
            },
            {
              label: "No",
            },
          ],
        });
  }
  unBlockedRecord;

  unBlockRecordMgs(record) {}

  deleteRecord(record) {
    axios
      .post(baseUrl + "/api/user-delete", { id: record.id })
      .then((res) => {
        this.setState({ count: 0 });
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }

  UserEnable2faStatus(record) {
    axios
      .post(baseUrl + "/api/user-2fastatus-change", { id: record.id })
      .then((res) => {
        this.setState({ count: 0 });
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }

  userKycStatus(record) {
    axios
      .post(baseUrl + "/api/user-kycstatus-change", { id: record.id })
      .then((res) => {
        this.setState({ count: 0 });
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }

  unBlockedRecord(record) {
    axios
      .post(baseUrl + "/api/user-unblock", { id: record.id })
      .then((res) => {
        this.setState({ count: 0 });
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <UserFund
            currentRecord={this.state.currentRecord}
            user={this.state.user}
            portfolioRecord={this.state.portfolioRecord}
          />
          <UserFundModal buyOrderDataById={this.state.buyOrderDataById} />
          <UserSellFundModal sellOrderDataById={this.state.sellOrderDataById} />
          <UserReferModal referHistory={this.state.referHistory} />
          <UserWithdrawalModal withrawHistory={this.state.withrawHistory} />
          <UserDepositeModal depositeHistory={this.state.depositeHistory} />
          {/* <UserTransferModal transferHistory={this.state.transferHistory} /> */}
          <div id="page-content-wrapper">
            <div className="container-fluid table_last">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              <h4 className="mt-2 man_hadding mb-3">Users </h4>
              <div className="white_box">
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  onPageChange={this.pageChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
  userdata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  userdata: state.userdata,
  records: state.records,
});

export default connect(mapStateToProps)(Users);
