import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@mkikets/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import { Link } from "react-router-dom";
import Moment from "moment";
import * as myConstList from "./baseUrl";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
const baseUrl = myConstList.baseUrl;
class RoleManager extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        key: "_id",
        text: "#",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
        key: "firstName",
        text: "Name",
        className: "name",
        align: "left",
        sortable: true,
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "role",
        text: "Role",
        className: "role",
        align: "left",
        sortable: true,
      },
      {
        key: "userType",
        text: "User Type",
        className: "userType",
        align: "left",
        sortable: true,
      },
      {
        key: "date",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{Moment(record.created).format("lll")}</Fragment>;
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {record.role == "admin" ? (
                ""
              ) : (
                <a
                  href={"/edit-role/" + record.id}
                  className="btn btn-primary btn-sm mr-2"
                  style={{ marginRight: "5px" }}
                >
                  <i className="fa fa-edit"></i>
                </a>
              )}

              <button
                className="btn btn-danger btn-sm"
                onClick={() => this.deleteRecordMgs(record)}
                disabled={record.role == "admin" ? true : false}
              >
                <i className="fa fa-trash"></i>
              </button>
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 20,
      length_menu: [10, 20, 50],
      filename: "Users Manager List",
      no_data_text: "No user found!",
      button: {
        print: true,
        csv: true,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
    };
    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    if (
      this.props.auth.user.roleManager !== undefined &&
      this.props.auth.user.roleManager !== 1
    ) {
      this.props.history.push("/dashboard");
    }
    if (
      this.props.roledata !== undefined &&
      this.props.roledata.role !== undefined &&
      this.props.roledata.role.data !== undefined &&
      this.props.roledata.role.data.message !== undefined &&
      this.props.roledata.role.data.success
    ) {
      toast(this.props.roledata.role.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
    this.getData();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.auth.user.roleManager !== undefined &&
      nextProps.auth.user.roleManager !== 1
    ) {
      this.props.history.push("/dashboard");
    }

    this.getData();
  }

  getData() {
    axios
      .post(baseUrl + "/api/role-manager-data")
      .then((res) => {
        this.setState({ records: res.data });
      })
      .catch();
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }
  deleteRecordMgs(record) {
    confirmAlert({
      title: "Confirm to submit",
      message: "Really want to delete this role?",
      buttons: [
        {
          label: "Yes",
          onClick: () => this.deleteRecord(record),
        },
        {
          label: "No",
        },
      ],
    });
  }
  deleteRecord(record) {
    axios
      .post(baseUrl + "/api/role-delete", { id: record.id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>

              <h3 className="mt-2 man_hadding mb-3">Role Manager</h3>
              <div className="white_box">
                <a
                  href={"/add-role"}
                  className="btn btn-primary float-right mb-3 mr-2"
                >
                  Add
                </a>
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columns}
                  onPageChange={this.pageChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

RoleManager.propTypes = {
  auth: PropTypes.object.isRequired,
  roledata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  roledata: state.roledata,
  records: state.records,
  errors: state.errors,
});

export default connect(mapStateToProps)(RoleManager);
