import React, { Component, Fragment, useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {
    BrowserRouter as Router,
    Route,
    Redirect,
    withRouter,
    useHistory
} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { addNewRoleData } from "../../actions/roleActions";
import Switch from "react-switch";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import ReactDatatable from "@mkikets/react-datatable";
import "react-phone-input-2/lib/style.css";
import { baseUrl } from './baseUrl';



const OrderReport = (props) => {

    const [state, setState] = useState({records:[]})

    const getData = () => {
        axios.get(baseUrl + "/api/get_order_report").then((res) => {
            if (res.data.status) {
                setState({records:res.data.data})
            }
            
        }).catch((err) => {

        })
    }

    useEffect(() => {
        getData()        
    }, [])


    const columns = [
        {
            // key: "id",
            text: "#",
            className: "id",
            align: "left",
            sortable: true,
            cell: (row, index) => index + 1,
        },       
        {
            key: "per_price",
            text: "Pair",
            className: "amount",
            align: "left",
            sortable: true,
            cell: (row, index) => {return row.get_coin+"/"+row.spend_coin}
        },
        {
            key: "get_amount",
            text: "Get",
            className: "amount",
            align: "left",
            sortable: true,
        },
        {
            key: "spend_amount",
            text: "Spend",
            className: "price",
            align: "left",
            sortable: true,
        }        

    ];

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Buy Order List",
        no_data_text: "No user found!",
        button: {
            print: true,
            csv: true,
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    const pageChange=(pageData) =>{
        console.log("OnPageChange", pageData);
      }


    return (
        <div>
            <Navbar />
            <div className="d-flex" id="wrapper">
                <Sidebar />
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-link mt-3" id="menu-toggle">
                            <FontAwesomeIcon icon={faList} />
                        </button>
                        <h3 className="mt-2 man_hadding">Order Report</h3>
                        <div className="in_page  update-role-main-outer">
                            <br />
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="white_box">
                                    <ReactDatatable
                                        config={config}
                                        records={state.records}
                                        columns={columns}
                                        onPageChange={pageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}





export default OrderReport