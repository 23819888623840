import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, Question } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from "@ashvin27/react-datatable";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer } from "react-toastify";
import Moment from "moment";
import * as myConstList from "./baseUrl";

const baseUrl = myConstList.baseUrl;
class Users_pending_record extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nationality: "indian",
    };

    this.columnsIndian = [
      {
        key: "_id",
        text: "SN",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
          key: "firstName",
          text: "Name",
          className: "firstName",
          align: "left",
          sortable: true,
      },
      {
        key: "mobile_no",
        text: "Mobile Number",
        className: "email",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <>{record.mobile_no ? record.mobile_no : "-"}</>;
        },
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      // {
      //     key: "country_code",
      //     text: "Nationality",
      //     className: "country_code",
      //     align: "left",
      //     sortable: true,
      //     cell: (record) => {
      //         return (
      //             <>
      //                 {record.country_code == "+91" ? "Indian" : "Non-Indian"}
      //             </>
      //         )
      //     }
      // },
      // {
      //   key: "passport_status",
      //   text: "Document Status",
      //   className: "identity_status",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         {record.passport_status === "2" ? (
      //           "Verified"
      //         ) : record.passport_status === "3" ? (
      //           <Link
      //             to={"/reject-reason/" + record.id + "/" + 21}
      //             className="btn5"
      //           >
      //             Rejected <i className="fa fa-info-circle"></i>
      //           </Link>
      //         ) : (
      //           "Pending"
      //         )}
      //       </Fragment>
      //     );
      //   },
      // },
      // {
      //     key: "identity_status",
      //     text: "Aadhaar Front",
      //     className: "identity_status",
      //     align: "left",
      //     sortable: true,
      //     cell: record => {

      //         return (
      //             <Fragment>
      //                 {record.identity_status == '1' ? "Pending" : (record.identity_status === '2' ? "Verified" : <Link to={"/reject-reason/" + record.id + "/" + 21} className="btn5">Rejected  <i className="fa fa-info-circle"></i></Link>)}

      //             </Fragment>
      //         );
      //     }
      // },
      // {
      //     key: "identity_status2",
      //     text: "Aadhaar Back",
      //     className: "identity_status2",
      //     align: "left",
      //     sortable: true,
      //     cell: record => {
      //         return (
      //             <Fragment>
      //                 {record.identity_status2 === '1' ? "Pending" : (record.identity_status2 === '2' ? "Verified" : <Link to={"/reject-reason/" + record.id + "/" + 22} className="btn5">Rejected  <i className="fa fa-info-circle"></i></Link>)}
      //             </Fragment>
      //         );
      //     }
      // },

      // {
      //     key: "image3_status",
      //     text: "Pan card",
      //     className: "addressStatus",
      //     align: "left",
      //     sortable: true,
      //     cell: record => {
      //         return (
      //             <Fragment>
      //                 {record.image3_status === '1' ? "Pending" : (record.image3_status === '2' ? "Verified" : <Link to={"/reject-reason/" + record.id + "/" + 23} className="btn5">Rejected  <i className="fa fa-info-circle"></i></Link>)}
      //             </Fragment>
      //         );
      //     }
      // },
      // {
      //   key: "selfie_status",
      //   text: "Selfie",
      //   className: "selfie_status",
      //   align: "left",
      //   sortable: true,
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         {record.selfie_status === "2" ? (
      //           "Verified"
      //         ) : record.selfie_status === "3" ? (
      //           <Link
      //             to={"/reject-reason/" + record.id + "/" + 24}
      //             className="btn5"
      //           >
      //             Rejected <i className="fa fa-info-circle"></i>
      //           </Link>
      //         ) : (
      //           "Pending"
      //         )}
      //       </Fragment>
      //     );
      //   },
      // },
      {
        key: "createdAt",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{Moment(record.createdAt).format("lll")}</Fragment>;
        },
      },
      // {
      //   key: "action",
      //   text: "Action",
      //   className: "action",
      //   width: 100,
      //   align: "left",
      //   sortable: false,
      //   cell: (record) => {
      //     return (
      //       <Fragment>
      //         {/* {(record.identity_status !==undefined || record.identity_status2 !==undefined) && */}
      //         <Link
      //           to={"/verifyKyc/" + record.id + "/" + 2}
      //           className="admin_btn"
      //         >
      //           Verify
      //         </Link>
      //         {/* } */}
      //       </Fragment>
      //     );
      //   },
      // },
    ];

    this.columnsNonIndian = [
      {
        key: "_id",
        text: "SN",
        className: "id",
        align: "left",
        sortable: true,
        cell: (row, index) => index + 1,
      },
      {
          key: "firstName",
          text: "Name",
          className: "firstName",
          align: "left",
          sortable: true,
      },
      {
        key: "email",
        text: "Email",
        className: "email",
        align: "left",
        sortable: true,
      },
      {
        key: "country_code",
        text: "Nationality",
        className: "country_code",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <>{record.country_code == "+91" ? "Indian" : "Non-Indian"}</>;
        },
      },
      {
        key: "passport_status",
        text: "Document Status",
        className: "identity_status",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {/* {record.passport_status == '1' ? "Pending" : (record.passport_status === '2' ? "Verified" : <Link to={"/reject-reason/" + record.id + "/" + 21} className="btn5">Rejected  <i className="fa fa-info-circle"></i></Link>)} */}
              {record.selfie_status === "2" ? (
                "Verified"
              ) : record.selfie_status === "3" ? (
                <Link
                  to={"/reject-reason/" + record.id + "/" + 24}
                  className="btn5"
                >
                  Rejected <i className="fa fa-info-circle"></i>
                </Link>
              ) : (
                "Pending"
              )}
            </Fragment>
          );
        },
      },
      {
        key: "selfie_status",
        text: "Selfie",
        className: "selfie_status",
        align: "left",
        sortable: true,
        cell: (record) => {
          return (
            <Fragment>
              {/* {record.selfie_status === '1' ? "Pending" : (record.selfie_status === '2' ? "Verified" : <Link to={"/reject-reason/" + record.id + "/" + 24} className="btn5">Rejected <i className="fa fa-info-circle"></i></Link>)} */}
              {record.selfie_status === "2" ? (
                "Verified"
              ) : record.selfie_status === "3" ? (
                <Link
                  to={"/reject-reason/" + record.id + "/" + 24}
                  className="btn5"
                >
                  Rejected <i className="fa fa-info-circle"></i>
                </Link>
              ) : (
                "Pending"
              )}
            </Fragment>
          );
        },
      },
      {
        key: "createdAt",
        text: "Date",
        className: "date",
        align: "left",
        sortable: true,
        cell: (record) => {
          return <Fragment>{Moment(record.createdAt).format("lll")}</Fragment>;
        },
      },
      {
        key: "action",
        text: "Action",
        className: "action",
        width: 100,
        align: "left",
        sortable: false,
        cell: (record) => {
          return (
            <Fragment>
              {/* {(record.identity_status !==undefined || record.identity_status2 !==undefined) && */}
              <Link
                to={"/verifyKyc/" + record.id + "/" + 2}
                className="admin_btn"
              >
                Verify
              </Link>
              {/* } */}
            </Fragment>
          );
        },
      },
    ];

    this.config = {
      page_size: 10,
      length_menu: [10, 20, 50],
      filename: "Verify Pending List",
      no_data_text: "No user found!",
      button: {
        print: false,
        csv: false,
      },
      language: {
        length_menu: "Show _MENU_ result per page",
        filter: "Filter in records...",
        info: "Showing _START_ to _END_ of _TOTAL_ records",
        pagination: {
          first: "First",
          previous: "Previous",
          next: "Next",
          last: "Last",
        },
      },
      show_length_menu: true,
      show_filter: true,
      show_pagination: true,
      show_info: true,
    };

    this.state = {
      records: [],
      nationality: "indian",
    };

    this.state = {
      nationality: "indian",
      currentRecord: {
        id: "",
        name: "",
        email: "",
        password: "",
        password2: "",
      },
    };

    this.getData = this.getData.bind(this);
  }

  componentDidMount() {
    // if (this.props.auth.user.verifyPending !== undefined && this.props.auth.user.verifyPending !== 1) {
    //     this.props.history.push("/dashboard");
    // }
    this.getData();
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.auth.user.verifyPending !== undefined && nextProps.auth.user.verifyPending !== 1) {
    //     this.props.history.push("/dashboard");
    // }

    this.getData();
  }

  getData() {
    axios
      .post(baseUrl + "/api/user-pending-data",{lang:"kor"})
      .then((res) => {
        this.setState({ records: res.data });
        let filterData = res.data.filter((filterdData) => {
          return filterdData.country_code == "+91";
        });
        this.setState({ recordsss: filterData });
      })
      .catch();
  }

  editRecord(record) {
    this.setState({ currentRecord: record });
  }
  handleChange = (e) => {
    if (e.target.value == "indian") {
      let filterData = this.state.records.filter((filterdData) => {
        return filterdData.country_code == "+91";
      });
      this.setState({ recordsss: filterData, nationality: e.target.value });
    }
    if (e.target.value == "non-indian") {
      let filterData = this.state.records.filter((filterdData) => {
        return filterdData.country_code !== "+91";
      });
      this.setState({ recordsss: filterData, nationality: e.target.value });
    }
  };

  deleteRecord(record) {
    axios
      .post(baseUrl + "/api/user-delete", { _id: record._id })
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch();
    this.getData();
  }

  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }

  render() {
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <UserAddModal />
          <UserUpdateModal record={this.state.currentRecord} />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle">
                <FontAwesomeIcon icon={faList} />
              </button>
              <h4 className="mt-2 man_hadding mb-3">Korean Users Pending KYC List</h4>

              <div className="white_box">
                {/* <select onClick={this.handleChange} className="form-control w-25 float-right">
                                <option value="indian">Indian</option>
                                <option value="non-indian">Non-Indian</option>
                            </select> */}
                <ReactDatatable
                  config={this.config}
                  records={this.state.records}
                  columns={this.columnsIndian}
                  // columns={this.state.nationality == "indian"?this.columnsIndian:this.columnsNonIndian}
                  onPageChange={this.pageChange.bind(this)}
                />
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }
}

Users_pending_record.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,

  records: state.records,
});

export default connect(mapStateToProps)(Users_pending_record);
