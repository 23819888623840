
import React, { useState, useEffect, useRef } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function UpdateMarketTokenModal(props) {
    const ref = useRef();
    console.log("props", props)
    const [state, setState] = useState({ totalPrice: "", dbiRate: 0.2 })
    const [id, setId] = useState("")
    const [date, setDate] = useState("")
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState("")
    const [errorState, setErrorState] = useState({})

    const [dateErr, setDateErr] = useState("")
    const [titleErr, setTitleErr] = useState("")
    const [linkErr, setLinkErr] = useState("")
    const [descriptionErr, setDescriptionErr] = useState("")
    const [imageErr, setImageErr] = useState("")
    const [language, setLanguage] = useState("en")
    const [blogLink, setBlogLink] = useState("")
    const [buttonDisable, setButtonDisable] = useState(false)
    const [rateState, setRateState] = useState({ totalPrice: "", dbiRate: 0 })
    const [dbiUsdtCoin, setDbiUsdtCoin] = useState({});

    useEffect(() => {
        setState(props.currentRecord)
        setImage("")
        setErrorState({})
        console.log(ref);
        ref.current.value = "";
        // getCoinPair()
        // setDescription(props.currentRecord?.description ? props.currentRecord?.description : "")
        // setTitle(props.currentRecord.title)
        // setDate(props.currentRecord.date)
        // setId(props.currentRecord.id)

        setImageUrl(baseUrl + "/static/market_token/" + props.currentRecord.symbol)
        setRateState({ dbiRate: props.currentRecord.dbiRate, totalPrice: props.currentRecord.dbiRate * props.currentRecord.dbiUsdt, totalEthPrice: props.currentRecord.ethRate * props.currentRecord.dbiEth, ethRate: props.currentRecord.ethRate })
        // setLanguage(props.currentRecord.lang)
        // setBlogLink(props.currentRecord.link)
    }, [props])

    function validURL(str) {
        var pattern = new RegExp('^((ft|htt)ps?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name and extension
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?' + // port
            '(\\/[-a-z\\d%@_.~+&:]*)*' + // path
            '(\\?[;&a-z\\d%@_.,~+&:=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    const handleChange = (e) => {
        let error = {};
        const { name, value } = e.target;
        let totalPrice = "";
        console.log(state);
        console.log(name);
        console.log(value);

        // setState({ ...state, [name]: value })
        // error[name] = "";
        if (value == "") {
            error[name] = "This field is required"
        } else {
            if (name == "quantity") {
                if (value <= 0) {
                    error[name] = "Please enter valid number"
                }
            }
            else if (name == "user_min_limit") {
                if (value <= 0) {
                    error[name] = "Please enter valid number"
                } else if (value > state.user_limit) {
                    console.log(value, "  ", state.user_limit);
                    // error[name] = "Please enter value less then user max"
                }
            }
            else if (name == "user_limit") {
                if (value <= 0) {
                    error[name] = "Please enter valid number"
                } else if (value < state.user_min_limit) {
                    console.log(value, "  ", state.user_min_limit);
                    // error[name] = "Please enter value more then user min"
                }
            }
            else if (name == "name") {
                if (value.length <= 2) {
                    error[name] = "Please enter more then 2 charater."
                }
            }
            else if (name == "dbiUsdt") {
                if (value == 0) {
                    error[name] = "The Field is required";
                }
                else if (value < 0) {
                    error[name] = "The Price should not be negative";
                } else {
                    totalPrice = value * rateState.dbiRate;
                    totalPrice = totalPrice.toFixed(8);
                    setRateState({ ...rateState, totalPrice: totalPrice })
                }
            } 
            else if (name == "dbiEth") {
                if (value == 0) {
                    error[name] = "The Field is required";
                }
                else if (value < 0) {
                    error[name] = "The Price should not be negative";
                } else {
                    totalPrice =  (rateState.ethRate && rateState.ethRate != '') ? value * rateState.ethRate : 0;
                   // console.log(totalPrice);
                    totalPrice = totalPrice.toFixed(8);
                    // setState({ ...state, totalPrice: totalPrice })
                    setRateState({ ...rateState, totalEthPrice: totalPrice })

                }
            }
            
            
            else if (name == "date_of_live") {
                if (state.date_of_live_str < parseInt(Date.now() / 1000)) {
                    error[name] = "Date expire";
                }
            }
            else if (name == "date_of_expire") {
                const dateE = new Date(value);
                const dateL = new Date(state.date_of_live);
                console.log(dateE)
                console.log(dateL > dateE)
                if (dateL > dateE) {
                    error[name] = "The Date Must be greater then Live date";
                }

            }
        }
        setState({ ...state, [name]: value })

        console.log(error)
        setErrorState(error)
    }

    const handleChangeRate = (e) => {
        let error = {};
        const { name, value } = e.target;

        console.log(name);
        console.log(value);

       
        if (name == "dbiRate") {
            let tPrice = value * state.dbiUsdt;
            setRateState({ ...rateState, [name]: value, totalPrice: tPrice })
            if (value == 0) {
                error[name] = "The Field is required";
            } else {
                if (value <= 0) {
                    error[name] = "Please enter valid DBI";
                }
            }

        }
       else  if (name == "ethRate") {
            let tPrice = value * state.dbiEth;
            setRateState({ ...rateState, [name]: value, totalEthPrice: tPrice })
            if (value == 0) {
                error[name] = "The Field is required";
            } else {
                if (value <= 0) {
                    error[name] = "Please enter valid DBI";
                }
            }

        }
        setErrorState(error)

    }

    const getCoinPair = () => {

        axios.get(baseUrl + "/api/pair-data", { header: description, lang: language }).then((res) => {
            console.log(res.data);
            if (res.data) {
                const pairs = res.data;
                let pairPrice = pairs.find((val) => val.id == 235);
                setDbiUsdtCoin(pairPrice)
                setRateState({ ...rateState, dbiRate: pairPrice.current_price })
                console.log(pairPrice);
            }
        }).catch((err) => {

        })
    }

    const closeModal = () => {
        setErrorState({})
    }

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value })
        // if (name === "date") {
        //     setDate(value)
        //     if (value === "") {
        //         setDateErr("This field is required")
        //         return false
        //     }
        //     setDateErr("")
        // }
        // if (name === "title") {
        //     setTitle(value)
        //     if (value == "") {
        //         setTitleErr("This field is required")
        //         return false
        //     }
        //     setTitleErr("")
        // }
        // if (name === "lang") {
        //     setLanguage(value)

        // }

        // if (name == "link") {
        //     setBlogLink(value)
        //     let reg = new RegExp("^(http|https|ftp)\://([a-zA-Z0-9\.\-]+(\:[a-zA-Z0-9\.&amp;%\$\-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9\-]+\.)*[a-zA-Z0-9\-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(\:[0-9]+)*(/($|[a-zA-Z0-9\.\,\?\'\\\+&amp;%\$#\=~_\-]+))*$");
        //     if (value == "") {
        //         setLinkErr("This field is required")
        //         return false
        //     } else if (!reg.test(value)) {
        //         setLinkErr("Please Enter valid Url")
        //         return false
        //     }
        //     setLinkErr("")
        // }
        // if(name==="description"){
        //     setDescription(value)
        //     if(value===""){
        //         setDescriptionErr("This field is required")
        //         return false
        //     }
        //     setDescriptionErr("")
        // }
    }
    const handleImage = (e) => {
        let { value } = e.target;

        const icon = e.target.files[0];

        if (value !== "" || value !== undefined) {
            setImage(e.target.files[0]);
            setImageUrl(URL.createObjectURL(e.target.files[0]));
            setImageErr("");
        } else {
            setImage(e.target.files[0]);
            setImageUrl("");
            setImageErr("This field is required");
        }
        if (!icon.name.match(/\.(jpg|jpeg|png)$/)) {
            setImageErr("Select valid image format");
            setErrorState({ ...errorState, image: "Select valid image format" })
            return false;
        }
    };
    const handleSubmit = () => {
        try {
            console.log(state);

            let error = {};
            if (state.name == undefined || state.name === "") {
                error['name'] = "This field is required";
                // setDateErr("This field is required")
                // return false
            } else {
                if (state.name.trim() == "") {
                    error['name'] = "The input value is  Invalid";
                }
            }
            if (state.dbiUsdt == undefined || state.dbiUsdt === "") {
                error['dbiUsdt'] = "This field is required";
                //setTitleErr("This field is required")
                // return false
            }
            else {
                if (state.dbiUsdt <= 0) {
                    error['dbiUsdt'] = "Please enter valid Price";
                }
            }
            // if (state.country_id == undefined || state.country_id === "") {
            // error['country_id'] = "This field is required";
            // setLinkErr("This field is required")
            // return false
            // }
            if (state.date_of_live == undefined || state.date_of_live === "") {
                error['date_of_live'] = "This field is required";
                //setDescriptionErr("This field is required")
                //  return false
            }
            if (state.date_of_expire == undefined || state.date_of_expire === "") {
                error['date_of_expire'] = "This field is required";
                //setDescriptionErr("This field is required")
                //  return false
            }
            else {
                const dateE = new Date(state.date_of_expire);
                const dateL = new Date(state.date_of_live);
                console.log(dateE)
                console.log(dateL)
                console.log(dateL > dateE)
                if (dateL > dateE) {
                    error['date_of_expire'] = "The Date Must be greater then Live date";
                }

            }
            if (state.quantity == undefined || state.quantity === "") {
                error['quantity'] = "This field is required";
                // setImageErr("This field is required")
                //return false
            }
            else {
                if (state.quantity <= 0) {
                    error['quantity'] = "Please enter valid Quantity";
                }
            }
            if (state.user_limit == undefined || state.user_limit === "") {
                error['user_limit'] = "This field is required";
                //setImageErr("This field is required")
                //return false
            }
            else {
                if (state.user_limit <= 0) {
                    error['user_limit'] = "Please enter valid User limit";
                } else if (parseFloat(state.user_limit) < parseFloat(state.user_min_limit)) {
                    error['user_limit'] = "Please enter value more then user min"
                }
            }
            if (state.user_min_limit == undefined || state.user_min_limit === "") {
                error['user_min_limit'] = "This field is required";
                //setImageErr("This field is required")
                //return false
            }
            else {
                if (state.user_min_limit <= 0) {
                    error['user_min_limit'] = "Please enter valid User limit";
                } else if (parseFloat(state.user_limit) < parseFloat(state.user_min_limit)) {
                    error['user_min_limit'] = "Please enter value less then user max"
                }
            }
            if (state.description == undefined || state.description === "") {
                error['description'] = "This field is required";
                //setImageErr("This field is required")
                //return false
            }
            if (state.link == undefined || state.link === "") {
                error['link'] = "This field is required";
                //setTitleErr("This field is required")
                // return false
            } else {
                if (!validURL(state.link)) {
                    error['link'] = "Please enter valid link";
                }
            }
            if (rateState.dbiRate == undefined || rateState.dbiRate === "") {
                error['dbiRate'] = "This field is required";
                //setTitleErr("This field is required")
                // return false
            } else {
                if (rateState.dbiRate <= 0) {
                    error['dbiRate'] = "Please enter valid DBI";
                }

            }
            console.log(image);
            if (image != "") {
                if (image.type != "image/png" && image.type != "image/jpeg") {
                    error['image'] = "Please select image only";
                }
            }

            console.log(Object.keys(error).length);
            console.log(rateState);
            setErrorState(error)
            if (Object.keys(error).length == 0) {
                setButtonDisable(true)
                let formdata = new FormData
                formdata.append("name", state.name)
                // formdata.append("price", state.price)
                formdata.append("price", rateState.totalPrice)
                formdata.append("dbiRate", rateState.dbiRate)
                formdata.append("dbiUsdt", state.dbiUsdt)
                formdata.append("dbiEth", state.dbiEth)
                formdata.append("ethRate", rateState.ethRate)
                // formdata.append("country_id", state.country_id)
                formdata.append("date_of_expire", state.date_of_expire)
                formdata.append("description", state.description)
                formdata.append("user_min_limit", state.user_min_limit)
                formdata.append("symbol", image)
                formdata.append("date_of_live", state.date_of_live)
                formdata.append("quantity", state.quantity)
                formdata.append("user_limit", state.user_limit);
                formdata.append("id", state.id);
                formdata.append("link", state.link);


                axios.post(baseUrl + "/api/update_market_token", formdata).then((res) => {
                    if (res.data.status) {
                        setButtonDisable(false)
                        props.setRefresh(!props.refresh)
                        $('#update-user-modal').modal('hide');
                        setState({})

                    }
                }).catch((err) => {
                    setButtonDisable(false)
                })
            }
        } catch (e) {
            console.log(e);
        }
    }
    return (
        <div>
            <div className="modal fade" id="update-user-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Edit Market Token</h4>
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="update-user1">
                                <div className='mb-3'>
                                    <label className='form-label'>Token Name:</label>
                                    <input type='text' name="name" value={state.name} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{errorState.name}</span>
                                </div>
                                {/* <div className='mb-3'>
                                    <label className='form-label'>Country:</label>
                                    <select onChange={handleChange} value={state.country_id} name="country_id" className="form-select w-100 form-control">
                                        <option value="">Select</option>

                                        {props.countryList.map((val, index) =>
                                            <option value={val.id} key={index}>{val.name}</option>
                                        )}

                                    </select>
                                    <span style={{ color: "red" }}>{errorState.country_id}</span>

                                </div> */}
                                <div className='mb-3'>
                                    <label className='form-label'>Whitepaper Link:</label>
                                    <input type="text" name="link" value={state.link} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.link}</span>
                                </div>
                                <div className='mb-3 row'>
                                    <div className='col-lg-6'>
                                        <label className='form-label'>Price(USDT):</label>
                                        <input type='number' name="dbiUsdt" value={state.dbiUsdt} onChange={handleChange} className='form-control' />
                                        <span style={{ color: "red" }}>{errorState.dbiUsdt}</span>
                                    </div>
                                    <div className='col-lg-3'>
                                        <label className='form-label'>DBI:</label>
                                        <input type='number' name="dbiRate" value={rateState.dbiRate} className='form-control' onChange={handleChangeRate} />
                                        <span style={{ color: "red" }}>{errorState.dbiRate}</span>


                                    </div>
                                    <div className='col-lg-3'>
                                        <label className='form-label'>Total:</label>
                                        <input type='text' name="totalPrice" value={rateState.totalPrice} disabled className='form-control' />

                                    </div>
                                </div>
                                <div className='mb-3 row'>
                                    <div className='col-lg-6'>
                                        <label className='form-label'>Price(ETH):</label>
                                        <input type='number' name="dbiEth" value={state.dbiEth} onChange={handleChange} className='form-control' />
                                        
                                    </div>
                                    <div className='col-lg-3'>
                                        <label className='form-label'>DBI:</label>
                                        <input type='number' name="ethRate" value={rateState.ethRate} className='form-control' onChange={handleChangeRate} />
                                        


                                    </div>
                                    <div className='col-lg-3'>
                                        <label className='form-label'>Total:</label>
                                        <input type='text' name="totalEthPrice" value={rateState.totalEthPrice} disabled className='form-control' />

                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Date of Live:</label>
                                    <input type="date" name="date_of_live" value={state.date_of_live} onChange={handleChange} className='form-control' />
                                     {/* disabled={state.date_of_live_str < parseInt(Date.now() / 1000)} min={new Date().toJSON().slice(0, 10)} */}
                                    <span style={{ color: "red" }}>{errorState.date_of_live}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Date of Expire:</label>
                                    <input type="date" name="date_of_expire" value={state.date_of_expire} onChange={handleChange} className='form-control' min={new Date().toJSON().slice(0, 10)} />

                                    <span style={{ color: "red" }}>{errorState.date_of_expire}</span>
                                </div>
                                {/* <div className='mb-3'>
                                    <label className='form-label'>Title:</label>
                                    <textarea name="title" value={state.name} onChange={handleChange} className='form-control' />
                                    <span style={{ color: "red" }}>{titleErr}</span>
                                </div> */}
                                <div className='mb-3'>
                                    <label className='form-label'>Available quantity:</label>
                                    <input type='number' name="quantity" value={state.quantity} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.quantity}</span>
                                </div>

                                <div className='mb-3'>
                                    <label className='form-label'>Per User Min Limit:</label>
                                    <input type='number' name="user_min_limit" value={state.user_min_limit} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.user_min_limit}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Per User Max Limit:</label>
                                    <input type='number' name="user_limit" value={state.user_limit} onChange={handleChange} className='form-control' />

                                    <span style={{ color: "red" }}>{errorState.user_limit}</span>
                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Description:</label>
                                    <textarea type='number' name="description" value={state.description} onChange={handleChange} className='form-control' >
                                    </textarea>

                                    <span style={{ color: "red" }}>{errorState.description}</span>
                                </div>
                                <div className='mb-3'>
                                    <img
                                        style={{ width: "100px" }}
                                        src={imageUrl}
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <label className='form-label'>Image:</label>
                                    <input type="file" name="image" onChange={handleImage} className='form-control' accept="image/png, image/jpeg, image/jpg" ref={ref} />
                                    <span style={{ color: "red" }}>{errorState.image}</span>
                                </div>




                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={closeModal}>Close</button>
                            <button
                                disabled={buttonDisable}
                                form="update-user1"
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateMarketTokenModal
