import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { addBanner } from "../../actions/bannerAction";
import { withRouter } from "react-router-dom";
import { baseUrl } from '../pages/baseUrl'
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class BannerAddModel extends React.Component {

    constructor() {
        super();
        this.state = {
            name: "",
            short_name: "",
            order_sequence: "",
            errors: {},
            coinImage: '',
            is_deposit: 0,
            is_withdrawal: 0,
            is_trade: 0,
            link: "",
            currencyImage: "",
            lang:"en",
            error: {}
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }

    }
    handleChange = (event) => {
        let { name, value } = event.target;
        console.log("valuefdvfdv", event.target)
        this.setState({ [name]: value, emptyField: false, errMsg: "" });
    };

    onChange = e => {
        // console.log(e.target);

        if (e.target.name === 'currencyImage') {
            var currencyImage = e.target.files[0];

            var fileSize = e.target.files[0].size;
            if (fileSize > 1000000) {
                this.setState({ invalidImage: "Maximum 1 MB Image allowed.", userUploadImage: false });
                return false;
            }
            console.log(this.state.invalidImage);
            if (!currencyImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid image jpeg,png,gif.", userUploadImage: false });
                return false;
            }

            this.setState({
                coinImage: currencyImage,
                errors: { currencyImage: "" }

            });
            
        }
        if (e.target.name === 'currencyImage') {
            this.setState({ [e.target.name]: e.target.value });
        }else {
            this.setState({ [e.target.id]: e.target.value });
        }

        if (e.target.name === 'link') {
            this.setState({ [e.target.id]: e.target.value });
            if(this.validateUrl(e.target.value)){
                
            }
            

        }

        
    };

     validateUrl = (value) =>{
        return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
      }

    closeModal = () => {
        console.log("close");
        this.setState({ currencyImage: "", coinImage: "" })
    }

    onCryptoAdd = e => {
        e.preventDefault();
         console.log("YTest", this.state);        
         this.setState({
            errors: { name: "",link: ""  }
        });
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        if (this.state.name == "") {
            this.setState({
                errors: { name: "Banner name is required" }
            });
            return false
        }
        if (this.state.link == "") {
            this.setState({
                errors: { link: "Banner link is required" }
            });
            return false
        }else{
            if(!this.validateUrl(this.state.link)){
                this.setState({
                    errors: { link: "Banner link is Invalid" }
                });
                return false
            }
        }
        if (!this.state.coinImage || this.state.coinImage == "") {
            this.setState({
                errors: { currencyImage: "Image is required" }
            });
            return false
        }

        let formData = new FormData();
        formData.append('image', this.state.coinImage);
        formData.append('banner_name', this.state.name);
        formData.append('banner_link', this.state.link);
        formData.append('lang', this.state.lang);
        // this.props.addBanner(formData,config);

        axios.post(baseUrl + "/api/addBanner", formData).then((res) => {
            console.log(res);
            if (res.data.status) {
                this.state.name = "";
                this.state.link = "";
                $('#add-banner-modal').modal('hide');
                window.location.reload();
            }
            //    dispatch({
            //        type: 'BANNER_ADD',
            //        payload: res,
            //    })
        }).catch(err => {
            //    dispatch({
            //        type: GET_ERRORS,
            //        payload: err.response.data
            //    })
        });

    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-banner-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Banner Add Model</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCryptoAdd} id="add-user">
                
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Banner Language</label>
                                        </div>
                                        <div className="col-md-9">
                                            <select onChange={this.onChange} value={this.state.language} name="lang" id="lang" className="form-select w-100 form-control">

                                                <option value="en">English</option>
                                                <option value="ko">Korean</option>
                                                <option value="rus">Russian</option>
                                                <option value="per">Persian</option>
                                                <option value="ar">Arabic</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Banner Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                name="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })} />
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Banner link</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.link}
                                                id="link"
                                                name="link"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.link
                                                })} />
                                            <span className="text-danger">{errors.link}</span>
                                        </div>
                                    </div>


                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Banner Image:</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                value={this.state.currencyImage}
                                                accept="image/*"
                                                onChange={this.onChange}
                                                // value={this.state.currencyImage}
                                                id="currencyImage"
                                                name="currencyImage"
                                                type="file"
                                                error={errors.currencyImage}
                                                className={classnames("form-control", {
                                                    invalid: errors.currencyImage
                                                })} />

                                            <span className="text-danger">{errors.currencyImage}</span>
                                        </div>
                                    </div>




                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeModal}>Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

BannerAddModel.propTypes = {
    addBanner: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addBanner }

)(withRouter(BannerAddModel));