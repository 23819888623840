import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from "react-router-dom";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactDatatable from "@mkikets/react-datatable";
import Moment from "moment";
import axios from "axios"
import { toast, ToastContainer } from "react-toastify";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { baseUrl } from './baseUrl';

const ExcelUpload = () => {
    const fileref = useRef();
    const [inProgress, setInProgress] = useState(false)
    const [state, setState] = useState({ order_type: "sell", file: "", searchUser: "" })
    const [errors, setErrors] = useState({})
    const [record, setRecord] = useState([]);
    const [userList, setUserList] = useState([]);
    const [uploadedExcelList, setUploadedExcelList] = useState([]);
    const [searchUserList, setSearchUserList] = useState([]);

    useEffect(() => {
        getPairData();
        getData()
        ///getCountry()
    }, [])
    const getPairData = () => {
        axios
            .get(baseUrl + "/api/coin_pair_data")
            .then(res => {
                // console.log(res.data);
                setRecord(res.data);
            })
            .catch();
    }

    const getData = () => {

        axios
            .get(baseUrl + "/api/get_user_list")
            .then(res => {
                // console.log(res.data);
                setUserList(res.data);
            })
            .catch();
        axios
            .get(baseUrl + "/api/get_uploaded_excel_list")
            .then(res => {
                console.log(res.data);
                setUploadedExcelList(res.data.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const config = {
        page_size: 10,
        length_menu: [10, 20, 50],
        filename: "Buy Order List",
        no_data_text: "No user found!",
        button: {
            print: true,
            csv: true,
        },
        language: {
            length_menu: "Show _MENU_ result per page",
            filter: "Filter in records...",
            info: "Showing _START_ to _END_ of _TOTAL_ records",
            pagination: {
                first: "First",
                previous: "Previous",
                next: "Next",
                last: "Last",
            },
        },
        show_length_menu: true,
        show_filter: true,
        show_pagination: true,
        show_info: true,
    };

    const columns = [
        {
            // key: "id",
            text: "#",
            className: "id",
            align: "left",
            sortable: true,
            cell: (row, index) => index + 1,
        },

        {
            key: "email",
            text: "Email",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "token_name",
            text: "Coin Pair",
            className: "title",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        {record.first_coin_short_name + "/" + record.second_coin_short_name}
                    </>
                );
            }
        },
        {
            key: "order_type",
            text: "Order Type",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "file_name",
            text: "File name",
            className: "title",
            align: "left",
            sortable: true,
        },
        {
            key: "date",
            text: "Date",
            className: "title",
            align: "left",
            sortable: true,
            cell: record => {
                return (
                    <>
                        {Moment.utc(record.createdAt).local().format("lll")}
                    </>
                );
            }
        },

        // {
        //     key: "action",
        //     text: "Action",
        //     className: "action",
        //     width: 100,
        //     align: "left",
        //     sortable: false,
        //     cell: record => {
        //         return (
        //             <>
        //                 {(record.status == "pending") ?
        //                     <>
        //                         <button
        //                             className="btn btn-primary btn-sm"
        //                             onClick={() => editRecord(record, true)}
        //                             style={{ marginRight: '5px' }}>
        //                             <i className="fa fa-check"></i>
        //                         </button>
        //                         <button
        //                             data-toggle="modal"
        //                             data-target="#add-user-modal"
        //                             className="btn btn-danger btn-sm"
        //                             onClick={() => editRecord(record, false)}>
        //                             <i className="fa fa-times"></i>
        //                         </button>
        //                     </>

        //                     : <></>
        //                 }
        //             </>
        //         );
        //     }
        // }
    ];

    const editRecord = (record, status) => {

    }

    const onChange = (e) => {
        console.log(e.target.name);
        console.log(e.target.value);
        let message = "";
        setState({ ...state, [e.target.name]: e.target.value })
        if (e.target.value == "") {
            if (e.target.name == "user") {
                message = "Please select user";
            } else if (e.target.name == "coin_pair") {
                message = "Please select coin pair";
            }

        }
        setErrors({ ...errors, [e.target.name]: message })
    }

    const onSearchUser = (e) => {
        console.log(e.target.name);
        console.log(e.target.value);
        const query = e.target.value;
        let filtered = [];
        setState({ ...state, [e.target.name]: e.target.value })
        if (e.target.value) {

            filtered = userList?.filter(item =>
                Object.values(item).some(value =>
                    typeof value === 'string' && value.toLowerCase().includes(query.toLowerCase())
                )
            );
        }
        setSearchUserList(filtered)
        console.log(filtered);
    }

    const selectedUser = (val) => {
        console.log(val);
        setState({ ...state, searchUser: val.email, user: val.id })
        setSearchUserList([])
    }

    const handleImage = (e) => {
        //accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        // let { value } = e.target;
        const file = e.target.files[0];
        // console.log(e.target.files[0]);
        if (file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            setState({ ...state, file: e.target.files[0] })
            setErrors({ ...errors, file: "" })

        } else {
            toast("Please choose xls files only.", {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
    }

    const submit = () => {
        console.log(state);
        let errorTemp = {}
        if (!state.coin_pair) {
            errorTemp.coin_pair = "Please select coin pair";
        }
        if (!state.user) {
            errorTemp.user = "Please select user";
        }
        if (!state.file) {
            errorTemp.file = "Please select excel file";
        } else {
            //if(Object.keys(state.file) == 0) {
            //  errorTemp.file = "Please select Excel File";
            //  }
        }
        setErrors(errorTemp)
        console.log(errorTemp);
        if (!Object.keys(errorTemp).length && !inProgress) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Really want to Upload ' + state.order_type + ' excel file records?',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => { confirmSubmit() }
                    },
                    {
                        label: 'No'

                    }
                ]
            });
        }
    }

    const confirmSubmit = () => {
        setInProgress(true)
        console.log("success");
        let formdata = new FormData
        formdata.append("coin_pair_id", state.coin_pair)
        formdata.append("user_id", state.user)
        formdata.append("file", state.file)
        formdata.append("order_type", state.order_type)

        console.log(formdata);
        axios.post(baseUrl + "/api/upload_excel_coin_pair", formdata).then((res) => {
            if (res.data.status) {
                toast("File uploaded Successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                })
                getData()
                setState({ ...state, coin_pair: "", user: "", file: "", searchUser: "" })
                fileref.current.value = "";
                setInProgress(false)

            } else {
                // fileref.current.value = "";
                // setState({...state, coin_pair: "", user: "", file: {} })
                setInProgress(false)
                toast(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                })
            }
        }).catch((err) => {
            console.log(err);
            setInProgress(false)
        })
    }

    const downloadFile = () => {
        window.location.href = "/buy_sell_format.xlsx"
    }


    return (
        <div>
            <Navbar />
            <div className="" id="wrapper">
                <Sidebar />
                {/* <ConfirmModal setRefresh={setRefresh} type={reason} currentRecord={currentRecord} countryList={countryList} /> */}
                <div id="page-content-wrapper">
                    <div className="container-fluid">
                        <button className="btn btn-link mt-3" id="menu-toggle">
                            <FontAwesomeIcon icon={faList} />
                        </button>

                        {/* <h4 className="mt-2 man_hadding mb-3">Token Detail</h4> */}
                        <div style={{ marginBottom: "10px" }}>
                            <div className='white_box'>

                                <div className="row">
                                    <div className="col-md-12">
                                        <h4 className="mt-2 man_hadding mb-3">Upload Excel</h4>
                                    </div>


                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-1">
                                                <span><input name="order_type" type='radio' value="buy" onChange={onChange} defaultChecked={state.order_type === "buy"} /> Buy</span>
                                            </div>
                                            <div className="col-md-3">
                                                <span><input name="order_type" type='radio' value="sell" onChange={onChange} defaultChecked={state.order_type === "sell"} /> Sell</span>
                                            </div>
                                        </div>


                                    </div>
                                    <div className="col-md-3">
                                        <button className="btn btn-success mr-2" onClick={downloadFile} >
                                            <i class="fa fa-download" aria-hidden="true"></i>
                                        </button>
                                        <span>Sample excel file</span>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label htmlFor="box1">Coin pair</label>
                                            <select
                                                className="form-control"
                                                name="coin_pair"
                                                value={state.coin_pair}
                                                error={errors.coin_pair}
                                                onChange={onChange}
                                            >
                                                <option value="">
                                                    Select
                                                </option>
                                                {record.map((value, i) =>
                                                    <option value={value.id} key={i}>{value.name1 + "/" + value.name2}</option>
                                                )}
                                            </select>
                                            <span className="text-danger">{errors.coin_pair}</span>

                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group position-relative">
                                            <label htmlFor="box2">User List</label>
                                            <input type="text" className="form-control" name="searchUser" onChange={onSearchUser} value={state.searchUser} />
                                            {/* <select
                                                className="form-control"
                                                id='box2'
                                                name="user"
                                                value={state.user}
                                                error={errors.user}
                                                onChange={onChange}
                                            >
                                                <option value="">
                                                    Select
                                                </option> */}
                                            <div className='search-list'>
                                                {searchUserList.map((value, i) =>

                                                    <option value={value.id} key={i} onClick={() => selectedUser(value)}>{value.email}</option>

                                                )}
                                            </div>
                                            {/* </select> */}
                                            <span className="text-danger">{errors.user}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-group">
                                            <label htmlFor="box3">Select Excel File </label>
                                            <input className="form-control" id='box3' type='file' name="upload_file" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" onChange={(e) => handleImage(e)} ref={fileref} />
                                            <span className="text-danger">{errors.file}</span>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button className="btn btn-primary" onClick={submit} disabled={inProgress}> Submit </button>
                                </div>
                            </div>
                        </div>
                        <div className='white_box'>
                            <div>
                                <ReactDatatable
                                    config={config}
                                    records={uploadedExcelList}
                                    columns={columns}

                                />
                            </div>

                        </div>
                    </div>

                    <ToastContainer />

                </div>
            </div>
        </div>
    )

}

export default ExcelUpload