import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import Country from "./components/pages/Country";
import Commission from "./components/pages/Commission";
import Balance from "./components/pages/Balance";
import State from "./components/pages/State";
import Loantovalue from "./components/pages/Loantovalue";
import Intrestrate from "./components/pages/Intrestrate";
import Margincall from "./components/pages/Margincall";
import Liquidationvalue from "./components/pages/Liquidationvalue";
import User from "./components/pages/Users";
import Pair from "./components/pages/Pair";
import BOT from "./components/pages/bot/BOT";
import Crypto from "./components/pages/Crypto";
import coin_user_list from "./components/pages/coin_user_list";
import coin_user_balance from "./components/pages/coin_user_balance";
import Contest from "./components/pages/Contest";
import Suport from "./components/pages/Suport";
import ChatRoom from "./components/pages/ChatRoom";
import AddContest from "./components/pages/AddContest";
import EditContest from "./components/pages/EditContest";
import addRole from "./components/pages/addRole";
import EditRole from "./components/pages/EditRole";
import VerifyKyc from "./components/pages/VerifyKyc";
import VerifyKycKorean from "./components/pages/VerifyKyc";
import BuyOrder from "./components/pages/BuyOrder";
import OrderReport from "./components/pages/OrderReport";
import Withdrawals from "./components/pages/Withdrawals";
import SellOrder from "./components/pages/SellOrder";
import RoleManager from "./components/pages/RoleManager";
import profileUpdate from "./components/pages/ProfileUpdate";
import FundRequestVerify from "./components/pages/FundRequestVerify";
import DepositINR from "./components/pages/DepositINR";
import Withdrawal from "./components/pages/Withdrawal";
import LiquidyManager from "./components/pages/LiquidityManager";
import FundRequest from "./components/pages/FundRequest";
import Users_complite_record from "./components/pages/Users_complite_record";
import Users_pending_record from "./components/pages/Users_pending_record";
import Users_complite_record_korien from "./components/pages/Users_complite_record_korien";
import Users_pending_record_korien from "./components/pages/Users_pending_record_korien";
import RejectReason from "./components/pages/RejectReason";
import React, { Component } from 'react';
import Login from "./components/auth/Login";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import InrRequest from "./components/pages/InrRequest";
import Credentials from "./components/pages/exchangecredentials/Credentials";
import CoinWithdrawal from "./components/pages/CoinWithdrawal";
import WitdrawalManager from "./components/pages/WithdrawalCommission"
import UserDepositHistory from "./components/pages/UserDepositHistory";
import PasswordChange from "./components/pages/PasswordChange";
import Faq from "./components/pages/Faq";
import ContactUs from "./components/pages/ContactUs";
import ReferalDepositRequest from "./components/pages/RefrealDepositRequest";
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';
import DepositFee from "./components/pages/DepositFee";
import FeeCollection from "./components/pages/FeeCollection";
import SellFeeCollection from "./components/pages/SellFeeCollection";
import WithdrawalFeesCollection from "./components/pages/WithdrawalFeesCollection";
import Banner from "./components/pages/Banner";
import Trading_steps from "./components/pages/Trading_steps";
import Popup from "./components/pages/Popup";
import Banner_text from "./components/pages/Banner_text";
import News from "./components/pages/News";
import Video from "./components/pages/Video";
import RefralManager from "./components/pages/RefralManager";
import Blog from "./components/pages/Blog";
import TokenExchange from "./components/pages/TokenExchange";
import MarketToken from "./components/pages/MarketToken";
import TokenDetail from "./components/pages/TokenDetail";
import UserDetail from "./components/pages/UserDetail";
import ExcelUpload from "./components/pages/ExcelUpload";
import TermsCondition from "./components/pages/TermsCondition";
import OpenPair from "./components/pages/OpenPair";
import Stacking from "./components/pages/Stacking";
import UsersStacking from "./components/pages/UsersStacking";



if (localStorage.jwtToken) {
    const token = localStorage.jwtToken;
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = "./login";
    }
}

class App extends Component {
    render() {
        return (
            <Provider store={store}>
                <Router>
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/forgot-password" component={ForgotPassword} />
                            <Route exact path="/password-reset/:userId/:token" component={ResetPassword} />
                            <Switch>
                                <PrivateRoute exact path="/commission" component={Commission} />
                                <PrivateRoute exact path="/depositFeeManager" component={DepositFee} />
                                <PrivateRoute exact path="/fee-collection" component={FeeCollection} />
                                <PrivateRoute exact path="/sell_fee-collection" component={SellFeeCollection} />
                                <PrivateRoute exact path="/withdrawal_fees-collection" component={WithdrawalFeesCollection} />
                                <PrivateRoute exact path="/UserDepositHistory" component={UserDepositHistory} />
                                <PrivateRoute exact path="/changePassword" component={PasswordChange} />
                                <PrivateRoute exact path="/faq" component={Faq} />
                                <PrivateRoute exact path="/balance" component={Balance} />
                                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                                <PrivateRoute exact path="/users" component={User} />
                                <PrivateRoute exact path="/country" component={Country} />
                                <PrivateRoute exact path="/state" component={State} />
                                <PrivateRoute exact path="/refer-deposit-list" component={ReferalDepositRequest} />
                                <PrivateRoute exact path="/deposit-inr" component={DepositINR} />
                                <PrivateRoute exact path="/withdrawal-inr" component={Withdrawal} />
                                <PrivateRoute exact path="/contest_management" component={Contest} />
                                <PrivateRoute exact path="/contact-us" component={ContactUs} />
                                <PrivateRoute exact path="/crypto_currency" component={Crypto} />
                                <PrivateRoute exact path="/coin_user_list/:coin" component={coin_user_list} />
                                <PrivateRoute exact path="/coin_user_balance/:coin" component={coin_user_balance} />
                                
                                <PrivateRoute exact path="/loantovalue" component={Loantovalue} />
                                <PrivateRoute exact path="/intrestrate" component={Intrestrate} />
                                <PrivateRoute exact path="/margincall" component={Margincall} />
                                <PrivateRoute exact path="/liquidationvalue" component={Liquidationvalue} />
                                <PrivateRoute exact path="/pair_management" component={Pair} />
                                <PrivateRoute exact path="/bot" component={BOT} />
                                <PrivateRoute exact path="/credentials" component={Credentials} />
                                <PrivateRoute exact path="/chat-room/:id/:status" component={ChatRoom} />
                                <PrivateRoute exact path="/verifyKyc/:id/:type" component={VerifyKyc} />
                                <PrivateRoute exact path="/verifyKycKorean/:id/:type" component={VerifyKycKorean} />
                                <PrivateRoute exact path="/users-stacking" component={UsersStacking} />
                                <PrivateRoute exact path="/stacking" component={Stacking} />
                                <PrivateRoute exact path="/edit-contest/:id" component={EditContest} />
                                <PrivateRoute exact path="/fund-request-verify/:id" component={FundRequestVerify} />
                                <PrivateRoute exact path="/suport" component={Suport} />
                                <PrivateRoute exact path="/users-complite-record" component={Users_complite_record} />
                                <PrivateRoute exact path="/Users-pending-record" component={Users_pending_record} />
                                
                                <PrivateRoute exact path="/korien-users-complite-record" component={Users_complite_record_korien} />
                                <PrivateRoute exact path="/korien-Users-pending-record" component={Users_pending_record_korien} />
                                <PrivateRoute exact path="/add-contest" component={AddContest} />
                                <PrivateRoute exact path="/fund-request" component={FundRequest} />
                                <PrivateRoute exact path="/buy-order" component={BuyOrder} />
                                <PrivateRoute exact path="/order-report" component={OrderReport} />                                
                                <PrivateRoute exact path="/sell-order" component={SellOrder} />
                                <PrivateRoute exact path="/role-manager" component={RoleManager} />
                                <PrivateRoute exact path="/add-role" component={addRole} />
                                <PrivateRoute exact path="/inr-request" component={InrRequest} />
                                <PrivateRoute exact path="/profile-update/:id" component={profileUpdate} />
                                <PrivateRoute exact path="/edit-role/:id" component={EditRole} />
                                <PrivateRoute exact path="/coinWithdrawal" component={CoinWithdrawal} />
                                <PrivateRoute exact path="/witdrawalManager" component={WitdrawalManager} />
                                <PrivateRoute exact path="/reject-reason/:id/:type" component={RejectReason} />
                                <PrivateRoute exact path="/banner-management" component={Banner} />
                                <PrivateRoute exact path="/trading-steps" component={Trading_steps} />
                                <PrivateRoute exact path="/pop-up" component={Popup} />
                                <PrivateRoute exact path="/banner-text" component={Banner_text} />
                                <PrivateRoute exact path="/user-data" component={News} />
                                <PrivateRoute exact path="/open-pair" component={OpenPair} />
                                <PrivateRoute exact path="/video-management" component={Video} />
                                <PrivateRoute exact path="/liquidity-management" component={LiquidyManager} />
                                <PrivateRoute exact path="/withdrawals/:token" component={Withdrawals} />
                                <PrivateRoute exact path="/refralmanager" component={RefralManager} />
                                <PrivateRoute exact path="/blog" component={Blog} />
                                <PrivateRoute exact path="/token_exchange" component={TokenExchange} />
                                <PrivateRoute exact path="/market-token" component={MarketToken} />
                                <PrivateRoute exact path="/token-detail/:tokenId" component={TokenDetail} />
                                <PrivateRoute exact path="/user-detail/:userId" component={UserDetail} />
                                <PrivateRoute exact path="/excel_upload" component={ExcelUpload} />
                                <PrivateRoute exact path="/term_condition" component={TermsCondition} />
                                
                                
                            </Switch>
                            <Route exact path="*" component={NotFound} />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;
