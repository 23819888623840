
import React, { Component, Fragment, useState, useEffect } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  withRouter,
  useHistory
} from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import { addNewRoleData } from "../../actions/roleActions";
import Switch from "react-switch";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer } from "react-toastify";
import $ from "jquery";
import classnames from "classnames";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";



const AddRole = (props) => {
  console.log(props);
  const history = useHistory();
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile_no: "",
    role: "", 
    password: "",   
    intrestRate: 0,
    marginCall: 0,
    liquidations: 0,
    cryptoCurrency: 0,
    pairManagement: 0,
    botManagement: 0,
    liquidityManagement: 0,
    buyOrder: 0,
    sellOrder: 0,
    manageUsers: 0,
    fundRequest: 0,
    roleManager: 0,
    suportManagement: 0,
    verifyComplete: 0,
    koreanVerifyComplete:0,
    verifyPending: 0,
    manageBot: 0,
    contactUs: 0,
    faq: 0,
    banner: 0,
    tradingStep: 0,
    bannerText: 0,
    popUp: 0,
    blog: 0,
    tokenExchange: 0,
    marketToken: 0,
    excelUpload:0,
    setting:0,
    services:0,   
    
  })
  const [errors, setError] = useState({})



  const onChange = (e) => {
    console.log(e.target);
    // setState({ errors: {} });
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const onSwitchChange = (name) => {
    setState({ ...state, [name]: !state[name] });
  }

  const onRoleAdd = async (e) => {
    e.preventDefault();
    const newdata = state;
    // {
    //   name: state.name,
    //   email: state.email,
    //   mobile_no: state.mobile_no,
    //   role: state.role,
    //   loan: state.loan,
    //   intrestRate: state.intrestRate,
    //   marginCall: state.marginCall,
    //   liquidations: state.liquidations,
    //   cryptoCurrency: state.cryptoCurrency,
    //   pairManagement: state.pairManagement,
    //   botManagement: state.botManagement,
    //   liquidityManagement: state.liquidityManagement,
    //   buyOrder: state.buyOrder,
    //   sellOrder: state.sellOrder,
    //   manageUsers: state.manageUsers,
    //   fundRequest: state.fundRequest,
    //   roleManager: state.roleManager,
    //   suportManagement: state.suportManagement,
    //   verifyComplete: state.verifyComplete,
    //   verifyPending: state.verifyPending,
    //   password: state.password,
    // };
    console.log(newdata, "data");
    // return;
    let resp = await addNewRoleData(newdata);
    console.log(resp);
    if (resp.success) {
      history.push('/role-manager');
    }
  };


  return (
    <div>
      <Navbar />
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <button className="btn btn-link mt-3" id="menu-toggle">
              <FontAwesomeIcon icon={faList} />
            </button>
            <h3 className="mt-2 man_hadding">Add Role</h3>
            <div className="in_page row update-role-main-outer">
              <br />
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className=" padding_d">
                  <ul className="nav nav_left_tab">
                    <li className="nav-item">
                      <a
                        className="active show text-black"
                        data-toggle="tab"
                        href="#details"
                      >
                        <i className="la la-mobile-phone l_i"></i>
                        Role Add
                      </a>
                    </li>
                  </ul>
                  <hr />
                  <form noValidate onSubmit={onRoleAdd} id="add-user" autocomplete="off">
                    <div className="white_box">
                      <div className="tab-content ">
                        <div className="tab-pane active show" id="details">
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="contestStatus"
                              >
                                Role Type
                              </label>
                            </div>
                            <div className="col-md-9">
                              <select
                                class="browser-default custom-select"
                                name="role"
                                onChange={onChange}
                              >
                                <option>Please Select</option>
                                <option value="Manager">Manager</option>
                                <option value="Employee">Employee</option>
                              </select>
                              <span className="text-danger">
                                {errors.role}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="name">
                                Full Name
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                onChange={onChange}
                                value={state.name}
                                error={errors.name}
                                name="name"
                                type="text"
                                className={classnames("form-control", {
                                  invalid: errors.name,
                                })}
                              />
                              <span className="text-danger">
                                {errors.name}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="email">
                                Email
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                onChange={(e) => onChange(e)}
                                value={state.email}
                                error={errors.email}
                                name="email"
                                type="email"
                                autocomplete="off"
                                className={classnames("form-control", {
                                  invalid: errors.email,
                                })}
                              />
                              <span className="text-danger">
                                {errors.email}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="mobile">
                                Mobile Number
                              </label>
                            </div>
                            <div className="col-md-9">
                              <PhoneInput
                                country={"us"}
                                value={state.mobile_no}
                                onChange={(phone) => {
                                  setState({ ...state, mobile_no: phone });
                                  if (!phone) {
                                    setState({
                                      errors: {
                                        mobile: "This filed is required",
                                      },
                                      submitBtnDisable: false,
                                    });
                                    return false;
                                  }

                                  if (phone.length < 4) {
                                    setError({
                                      errors: {
                                        mobile:
                                          "Please enter a valid phone number",
                                      },
                                      submitBtnDisable: false,
                                    });
                                    return false;
                                  }
                                  setError({ errors: { mobile: "" } });
                                }}
                                placeholder="Mobile"
                                enableSearch={true}
                                containerClass="baseclass"
                                inputClass="somebaseclass"
                                countryCodeEditable={false}
                                name="mobile_number"
                                inputProps={{
                                  autoFocus: true,
                                }}
                              />

                              {/* <input
                                    onChange={onChange}
                                    value={state.mobile}
                                    error={errors.mobile}
                                    name="mobile"
                                    type="text"
                                    className={classnames("form-control", {
                                      invalid: errors.mobile,
                                    })}
                                  /> */}
                              <span className="text-danger">
                                {errors.mobile}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="password"
                              >
                                Password
                              </label>
                            </div>
                            <div className="col-md-9">
                              <input
                                onChange={(e) => onChange(e)}
                                value={state.password}
                                error={errors.password}
                                name="password"
                                type="password"
                                autocomplete="off"
                                className={classnames("form-control", {
                                  invalid: errors.password,
                                })}
                              />
                              <span className="text-danger">
                                {errors.password}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="cryptoCurrency"
                              >
                                Currency Manage
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.cryptoCurrency == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="cryptoCurrency"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.cryptoCurrency == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="cryptoCurrency"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                              <span className="text-danger">
                                {errors.contestStatus}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="intrestRate"
                              >
                                Commission Manage
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.intrestRate == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="intrestRate"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.intrestRate == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="intrestRate"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="manageUsers"
                              >
                                Manage Users
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.manageUsers == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="manageUsers"
                                    type="radio"
                                    className="form-check-input"
                                    placeholder="Enter Document No"
                                  />
                                  <span className="text-danger">
                                    {errors.idCard}
                                  </span>
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.manageUsers == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="manageUsers"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="manageUsers"
                              >
                                Manage BOT
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.manageBot == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="manageBot"
                                    type="radio"
                                    className="form-check-input"
                                    placeholder="Enter Document No"
                                  />
                                  <span className="text-danger">
                                    {errors.idCard}
                                  </span>
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.manageBot == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="manageBot"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="verifyComplete"
                              >
                                Kyc Manage
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.verifyComplete == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="verifyComplete"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.verifyComplete == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="verifyComplete"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="verifyComplete"
                              >
                                Korean Kyc Manage
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.koreanVerifyComplete == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="koreanVerifyComplete"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.koreanVerifyComplete == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="koreanVerifyComplete"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="marginCall"
                              >
                                Deposite Manage
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.marginCall == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="marginCall"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.marginCall == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="marginCall"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="fundRequest"
                              >
                                Withdraw Manage
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.fundRequest == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="fundRequest"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.fundRequest == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="fundRequest"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="buyOrder"
                              >
                                Order List
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.buyOrder == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="buyOrder"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.buyOrder == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="buyOrder"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                              <span className="text-danger">
                                {errors.contestStatus}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="liquidations"
                              >
                                Live Account Balance
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.liquidations == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="liquidations"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.liquidations == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="liquidations"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="roleManager">Role Manage</label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">

                                  <input
                                    checked={state.roleManager == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="roleManager"
                                    type="radio" className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.roleManager == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="roleManager"
                                    type="radio" className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="suportManagement"
                              >
                                Support Manage
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.suportManagement == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="suportManagement"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.suportManagement == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="suportManagement"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label
                                className="text-black"
                                htmlFor="liquidityManagement"
                              >
                                Liquidity Manage
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={
                                      state.liquidityManagement == 1
                                    }
                                    onChange={onChange}
                                    value="1"
                                    name="liquidityManagement"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={
                                      state.liquidityManagement == 0
                                    }
                                    onChange={onChange}
                                    value="0"
                                    name="liquidityManagement"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>


                          {/* <div className="row mt-2">
                                <div className="col-md-3">
                                  <label
                                    className="text-black"
                                    htmlFor="botManagement"
                                  >
                                    Referral Manage
                                  </label>
                                </div>
                                <div className="col-md-9">
                                  <div className="form-check-inline">
                                    <label className="form-check-label text-black">
                                      <input
                                        checked={state.botManagement == 1}
                                        onChange={onChange}
                                        value="1"
                                        name="botManagement"
                                        type="radio"
                                        className="form-check-input"
                                      />
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check-inline">
                                    <label className="form-check-label text-black">
                                      <input
                                        checked={state.botManagement == 0}
                                        onChange={onChange}
                                        value="0"
                                        name="botManagement"
                                        type="radio"
                                        className="form-check-input"
                                      />
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div> */}


                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="contactUs">
                                Contact Us
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.contactUs == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="contactUs"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.contactUs == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="contactUs"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="faq">
                                FAQ
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.faq == 1}
                                    onChange={onChange}
                                    value="1"
                                    name="faq"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  Yes
                                </label>
                              </div>
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.faq == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="faq"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="banner">
                                Banner
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("banner")} checked={state.banner == 1 ? true : false} />
                                  {/* <input
                                        checked={state.loan == 1}
                                        onChange={onChange}
                                        value="1"
                                        name="loan"
                                        type="radio"
                                        className="form-check-input"
                                      /> */}

                                </label>
                              </div>
                              {/* <div className="form-check-inline">
                                    <label className="form-check-label text-black">
                                      <input
                                        checked={state.loan == 0}
                                        onChange={onChange}
                                        value="0"
                                        name="loan"
                                        type="radio"
                                        className="form-check-input"
                                      />
                                      No
                                    </label>
                                  </div> */}
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="tradingStep">
                                Trading Step
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("tradingStep")} checked={state.tradingStep == 1 ? true : false} />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="bannerText">
                                Banner Text
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("bannerText")} checked={state.bannerText == 1 ? true : false} />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="popUp">
                                Pop Up
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("popUp")} checked={state.popUp == 1 ? true : false} />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="blog">
                                Blog
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                <Switch onChange={() => onSwitchChange("blog")} checked={state.blog == 1 ? true : false} />
                                  
                                </label>
                              </div>
                              {/* <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <input
                                    checked={state.blog == 0}
                                    onChange={onChange}
                                    value="0"
                                    name="blog"
                                    type="radio"
                                    className="form-check-input"
                                  />
                                  No
                                </label>
                              </div> */}
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="tokenExchange">
                                Token Exchange
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("tokenExchange")} checked={state.tokenExchange == 1 ? true : false} />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="marketToken">
                                Market Token
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("marketToken")} checked={state.marketToken == 1 ? true : false} />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="excelUpload">
                                Excel Upload
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("excelUpload")} checked={state.excelUpload == 1 ? true : false} />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="setting">
                                Setting
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("setting")} checked={state.setting == 1 ? true : false} />
                                </label>
                              </div>
                            </div>
                          </div>

                          <div className="row mt-2">
                            <div className="col-md-3">
                              <label className="text-black" htmlFor="services">
                                Services
                              </label>
                            </div>
                            <div className="col-md-9">
                              <div className="form-check-inline">
                                <label className="form-check-label text-black">
                                  <Switch onChange={() => onSwitchChange("services")} checked={state.services == 1 ? true : false} />
                                </label>
                              </div>
                            </div>
                          </div>


                          {/* <div className="row mt-2">
                                                              <div className="col-md-3">
                                                                  <label className="text-black" htmlFor="pairManagement">Pair Management</label>
                                                              </div>
                                                              <div className="col-md-9">
                                                                  <div className="form-check-inline">
                                                                      <label className="form-check-label text-black">
  
                                                                          <input
                                                                              checked={state.pairManagement == 1}
                                                                              onChange={onChange}
                                                                              value="1"
                                                                              name="pairManagement"
                                                                              type="radio" className="form-check-input"
  
  
                                                                          />
                                                                          Yes
                                                                      </label>
                                                                  </div>
                                                                  <div className="form-check-inline">
                                                                      <label className="form-check-label text-black">
                                                                          <input
                                                                              checked={state.pairManagement == 0}
                                                                              onChange={onChange}
                                                                              value="0"
                                                                              name="pairManagement"
                                                                              type="radio" className="form-check-input"
  
  
                                                                          />
                                                                          No
                                                                      </label>
                                                                  </div>
  
                                                              </div>
                                                          </div> */}



                          {/* <div className="row mt-2">
                                                              <div className="col-md-3">
                                                                  <label className="text-black" htmlFor="sellOrder">Sell Order</label>
                                                              </div>
                                                              <div className="col-md-9">
                                                                  <div className="form-check-inline">
                                                                      <label className="form-check-label text-black">
  
                                                                          <input
                                                                              checked={state.sellOrder == 1}
                                                                              onChange={onChange}
                                                                              value="1"
                                                                              name="sellOrder"
                                                                              type="radio" className="form-check-input"
  
  
                                                                          />
                                                                          Yes
                                                                      </label>
                                                                  </div>
                                                                  <div className="form-check-inline">
                                                                      <label className="form-check-label text-black">
                                                                          <input
                                                                              checked={state.sellOrder == 0}
                                                                              onChange={onChange}
                                                                              value="0"
                                                                              name="sellOrder"
                                                                              type="radio" className="form-check-input"
  
  
                                                                          />
                                                                          No
                                                                      </label>
                                                                  </div>
                                                                  <span className="text-danger">{errors.contestStatus}</span>
                                                              </div>
                                                          </div> */}

                          {/* <div className="row mt-2">
                                                              <div className="col-md-3">
                                                                  <label className="text-black" htmlFor="verifyPending">Verify Pending</label>
                                                              </div>
                                                              <div className="col-md-9">
                                                                  <div className="form-check-inline">
                                                                      <label className="form-check-label text-black">
  
                                                                          <input
                                                                              checked={state.verifyPending == 1}
                                                                              onChange={onChange}
                                                                              value="1"
                                                                              name="verifyPending"
                                                                              type="radio" className="form-check-input"
  
  
                                                                          />
                                                                          Yes
                                                                      </label>
                                                                  </div>
                                                                  <div className="form-check-inline">
                                                                      <label className="form-check-label text-black">
                                                                          <input
                                                                              checked={state.verifyPending == 0}
                                                                              onChange={onChange}
                                                                              value="0"
                                                                              name="verifyPending"
                                                                              type="radio" className="form-check-input"
  
  
                                                                          />
                                                                          No
                                                                      </label>
                                                                  </div>
  
                                                              </div>
                                                          </div> */}

                          <hr />
                          <button type="submit" className="btn btn-success">
                            Save
                          </button>
                          <hr />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );

}

export default AddRole