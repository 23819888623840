import { fas } from '@fortawesome/free-solid-svg-icons'
import React, { useState, useEffect } from 'react'
import { baseUrl } from '../pages/baseUrl'
import axios from "axios"
import $ from 'jquery';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

function AddTermsAndCondition(props) {
   // console.log(props);
    const [date, setDate] = useState("")
    const [state, setState] = useState({ title: "", language: "en", type: 1, body: "" });
    const [errorState, setErrorState] = useState({})
    const [buttonDisable, setButtonDisable] = useState(false)
    const [rateState, setRateState] = useState({ totalPrice: "", dbiRate: "", dbiTotalRate: "" })
    const [editorValue, setEditorValue] = useState("");


    useEffect(() => {
        //console.log("effect call");
        setErrorState({})
        setState({ title: "",  language: "en", type: 1, body: "" }); 
    }, [props])


    const handleChange = (e) => {
        let error = {};
        const { name, value } = e.target;
        let totalPrice = "";
      //  console.log(name);
       // console.log(value);
        // error[name] = "";
        if (value == "") {
            error[name] = "This field is required"
        } else {

        }
        setState({ ...state, [name]: value })

       // console.log(error);
        setErrorState(error)
    }

    const handleEditorValue = (e) =>{      
        setEditorValue( e )
    }

    const closeModal = () => {
        console.log("close");
      //  props.setRefresh(!props.refresh)
        setState({ title: "", language: "en", type: 1 ,body: "" })
        setButtonDisable(false)
        setEditorValue("")
        setErrorState({})
        $('#add-terms-modal').modal('hide');

        // setImageData("")
        // setImage("")
        // setImageUrl("")
        // setTitle("")
        // setDescription("")
        // setDateErr("")
        // setTitleErr("")
        // setDescriptionErr("")
        // setLinkErr("")
        // setBlogLink("")
        // setImageErr("")
        // setLanguage("en")
    }



    const handleSubmit = () => {
        console.log(state);

        // setDescriptionErr("")
        let error = {};
        if (state.title == undefined || state.title === "") {
            error['title'] = "This field is required";
            // setDateErr("This field is required")
            // return false
        } else {
            if (state.title.trim() == "") {
                error['title'] = "The input value is  Invalid";
            }
        }

        if (editorValue == undefined || editorValue === "") {
            error['body'] = "This field is required";
            //setTitleErr("This field is required")
            // return false
        } else {
            if (editorValue.trim() == "") {
                error['body'] = "Please enter valid body";
            }
        }

        console.log(error);
        setErrorState(error)
        if (Object.keys(error).length == 0) {
            setButtonDisable(true)
            let formdata = state
            formdata.body = editorValue;
         //   console.log(formdata);
            axios.post(baseUrl + "/api/add_term_condition", formdata).then((res) => {
                if (res.data.status) {
                    //setState({ title: "", language: "en", type: 1, body: ""  })
                    
                    //window.location.reload();
                    closeModal()
                   // $('#add-terms-modal').modal('hide');
                    props.setRefresh(!props.refresh)
                    
                } else {
                    setButtonDisable(false)
                }
            }).catch((err) => {
                console.log(err)
                setButtonDisable(false)
            })
        }
    }

    return (
        <div>
            <div className="modal fade" id="add-terms-modal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Services</h4>
                            <button type="button" className="close" onClick={()=>closeModal()}>&times;</button>
                        </div>
                        <div className="modal-body">
                            <form id="update-user">
                                <div className='mb-3'>
                                    <label className='form-label'>Type:</label>
                                    <select onChange={(e)=>handleChange(e)} value={state.type} name="type" className="form-select w-100 form-control">
                                        <option value="1">Terms & Condition</option>
                                        <option value="2">Privacy & Policy</option>
                                        <option value="3">Risk Statement</option>
                                    </select>

                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Language:</label>
                                    <select onChange={(e)=>handleChange(e)} value={state.language} name="language" className="form-select w-100 form-control">

                                        <option value="en">English</option>
                                        <option value="ko">Korean</option>
                                        <option value="rus">Russian</option>
                                        <option value="per">Persian</option>
                                        <option value="ar">Arabic</option>


                                    </select>

                                </div>
                                <div className='mb-3'>
                                    <label className='form-label'>Title:</label>
                                    <input type='text' name="title" value={state.title} onChange={(e)=>handleChange(e)} className='form-control' />
                                    <span style={{ color: "red" }}>{errorState.title}</span>

                                </div>




                                <div className='mb-3'>
                                    <label className='form-label'>Body:</label>

                                    <CKEditor
                                        editor={ClassicEditor}
                                        data={editorValue}
                                        config={{
                                            toolbar: [
                                                "heading",
                                                "|",
                                                "bold",
                                                "italic",
                                                "link",
                                                "bulletedList",
                                                "numberedList",
                                                "blockQuote",
                                            ],
                                            heading: {
                                                options: [
                                                    {
                                                        model: "paragraph",
                                                        title: "Paragraph",
                                                        class: "ck-heading_paragraph",
                                                    },
                                                    {
                                                        model: "heading1",
                                                        view: "h1",
                                                        title: "Heading 1",
                                                        class: "ck-heading_heading1",
                                                    },
                                                    {
                                                        model: "heading2",
                                                        view: "h2",
                                                        title: "Heading 2",
                                                        class: "ck-heading_heading2",
                                                    },
                                                    {
                                                        model: "heading3",
                                                        view: "h3",
                                                        title: "Heading 3",
                                                        class: "ck-heading_heading3",
                                                    },
                                                ],
                                            },
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleEditorValue(data)

                                        }}
                                    />
                                    {/* <textarea type='number' name="body" value={state.body} onChange={handleChange} className='form-control' >
                                    </textarea> */}

                                    <span style={{ color: "red" }}>{errorState.body}</span>
                                </div>





                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={()=>closeModal()}>Close</button>
                            <button
                                disabled={buttonDisable}
                                form="update-user"
                                type="button"
                                className="btn btn-primary"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTermsAndCondition
